import { FileTypeEnum } from '@enums';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';
import { fichierRelations, hydrateAll, selectFichiersEntities } from './fichier-generated.selectors';

export * from './fichier-generated.selectors';

const filterFichiers = (fichiers: Dictionary<FichierEntityState>) =>
  Object.keys(fichiers)
    .filter(idFichier => fichiers[idFichier]?.fileType === FileTypeEnum.icon)
    .reduce((acc, idFichier) => Object.assign(acc, { [idFichier]: fichiers[idFichier] }), {});

const fichiersInObjectFiltered = (fichiers: Dictionary<FichierEntityState>) => ({
  fichiers: filterFichiers(fichiers)
});
const selectFichiersEntitiesDictionary = createSelector(selectFichiersEntities, fichiersInObjectFiltered);

const selectAllIconFichiersObject = createSelector(selectFichiersEntities, fichiers => {
  return hydrateAll({
    fichiers: filterFichiers(fichiers)
  });
});

const selectAllFichiersSelectors: Dictionary<Selector> = {};
export function selectAllIconFichiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Fichier>(
      schema,
      selectAllFichiersSelectors,
      selectFichiersEntitiesDictionary,
      getRelationSelectors,
      fichierRelations,
      hydrateAll,
      'fichier'
    );
  } else {
    return selectAllIconFichiersObject;
  }
}
