import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocietePatrimoineHierarchieAncetreApiService } from '@get/store/api-services';
import { GeneratedSocietePatrimoineHierarchieAncetreEffects } from './societe-patrimoine-hierarchie-ancetre-generated.effects';

@Injectable()
export class SocietePatrimoineHierarchieAncetreEffects extends GeneratedSocietePatrimoineHierarchieAncetreEffects {
  constructor(
    actions$: Actions,
    societePatrimoineHierarchieAncetreApiService: SocietePatrimoineHierarchieAncetreApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societePatrimoineHierarchieAncetreApiService, store$);
  }
}
