import { DroitState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { droitReducersGeneratedFunctions } from './droit-generated.reducer';

const droitReducersFunctions = [...droitReducersGeneratedFunctions];

const droitReducer = createReducer(DroitState.initialState, ...droitReducersFunctions);

export function reducer(state: DroitState.IState | undefined, action: Action) {
  return droitReducer(state, action);
}
