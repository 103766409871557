import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurComposantApiService } from '@get/store/api-services';
import { GeneratedValeurComposantEffects } from './valeur-composant-generated.effects';

@Injectable()
export class ValeurComposantEffects extends GeneratedValeurComposantEffects {
  constructor(actions$: Actions, valeurComposantApiService: ValeurComposantApiService, store$: Store<AppState>) {
    super(actions$, valeurComposantApiService, store$);
  }
}
