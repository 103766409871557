import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects } from './societe-config-analyse-synthese-societe-caracteristique-generated.effects';

@Injectable()
export class SocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects extends GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueEffects {
  constructor(
    actions$: Actions,
    societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeConfigAnalyseSyntheseSocieteCaracteristiqueApiService, store$);
  }
}
