import { CaracteristiqueTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { caracteristiqueTemplateReducersGeneratedFunctions } from './caracteristique-template-generated.reducer';

const caracteristiqueTemplateReducersFunctions = [...caracteristiqueTemplateReducersGeneratedFunctions];

const caracteristiqueTemplateReducer = createReducer(
  CaracteristiqueTemplateState.initialState,
  ...caracteristiqueTemplateReducersFunctions
);

export function reducer(state: CaracteristiqueTemplateState.IState | undefined, action: Action) {
  return caracteristiqueTemplateReducer(state, action);
}
