import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedPatrimoineHierarchieTemplateApiService } from './patrimoine-hierarchie-template-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class PatrimoineHierarchieTemplateApiService extends GeneratedPatrimoineHierarchieTemplateApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
