import { Injectable } from '@angular/core';
import { StoreActionType } from '@enums';
import {
  CaracteristiqueChoixTemplate,
  CaracteristiqueChoixTemplateEntityState,
  DynamicType
} from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateActions, CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplateApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { getActionsToNormalizeCaracteristiqueChoixTemplate } from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import { CaracteristiqueChoixTemplateSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  GeneratedCaracteristiqueChoixTemplateEffects,
  getDefaultAddCaracteristiqueChoixTemplateActions,
  getDefaultDeleteCaracteristiqueChoixTemplateActions
} from './caracteristique-choix-template-generated.effects';

@Injectable()
export class CaracteristiqueChoixTemplateEffects extends GeneratedCaracteristiqueChoixTemplateEffects {
  constructor(
    actions$: Actions,
    caracteristiqueChoixTemplateApiService: CaracteristiqueChoixTemplateApiService,
    store$: Store<AppState>
  ) {
    super(actions$, caracteristiqueChoixTemplateApiService, store$);
  }

  handleManyCaracteristiqueChoixTemplate$ = createEffect(() => {
    const selectCaracteristiqueChoixTemplateState$ = this.store$.select(
      CaracteristiqueChoixTemplateSelectors.selectCaracteristiqueChoixTemplateState
    );
    return this.actions$.pipe(
      ofType(CaracteristiqueChoixTemplateActions.handleManyCaracteristiqueChoixTemplate),
      withLatestFrom(selectCaracteristiqueChoixTemplateState$),
      switchMap(([params, state]) => {
        return this.caracteristiqueChoixTemplateApiService.handleManyCaracteristiqueChoixTemplate(params).pipe(
          mergeMap((updateObj: CaracteristiqueChoixTemplate[]) => {
            const actions: Action[] = [];

            if (params.itemsToDelete?.length) {
              for (let i = 0; i < params.itemsToDelete.length; i++) {
                const idCaracteristiqueChoixTemplate = params.itemsToDelete[i];
                actions.push(
                  ...getDefaultDeleteCaracteristiqueChoixTemplateActions(
                    state.entities[idCaracteristiqueChoixTemplate] as CaracteristiqueChoixTemplateEntityState
                  )
                );
              }
            }

            const itemsToUpdateObj = params.itemsToUpdate.reduce((acc, curr) => {
              acc[curr.idCaracteristiqueChoixTemplate] = curr;
              return acc;
            }, {} as DynamicType<CaracteristiqueChoixTemplate>);

            const updatedArray = [];
            for (let i = 0; i < updateObj.length; i++) {
              const idSocieteCaracteristiqueChoix = updateObj[i].idCaracteristiqueChoixTemplate;
              if (itemsToUpdateObj[idSocieteCaracteristiqueChoix]) {
                updatedArray.push(updateObj[i]);
              } else {
                // Laisser les 2 actions cohabiter car la seconde ne trigger pas la normalization correctement
                // (malgré le fait que le tableau contienne l'action)
                actions.push(
                  ...getActionsToNormalizeCaracteristiqueChoixTemplate([updateObj[i]], StoreActionType.upsert)
                );
                actions.push(
                  ...getDefaultAddCaracteristiqueChoixTemplateActions(updateObj[i], {
                    caracteristiqueTemplate: updateObj[i].idCaracteristiqueTemplate
                  })
                );
              }
            }
            if (updatedArray.length) {
              actions.push(...getActionsToNormalizeCaracteristiqueChoixTemplate(updatedArray, StoreActionType.upsert));
            }

            if (actions.length > 1) {
              return [
                getMultiAction(actions, CaracteristiqueChoixTemplateActions.handleManyCaracteristiqueChoixTemplate.type)
              ];
            }
            return actions;
          }),
          catchError(error =>
            of(CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure({ error }))
          )
        );
      })
    );
  });
}
