import { Injectable } from '@angular/core';
import { AnalyzeSyntheticConfigsToHandle, SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedSocieteConfigAnalyseSyntheseApiService } from './societe-config-analyse-synthese-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteConfigAnalyseSyntheseApiService extends GeneratedSocieteConfigAnalyseSyntheseApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public handleManySocieteConfigAnalyseSynthese(
    configs: AnalyzeSyntheticConfigsToHandle
  ): Observable<SocieteConfigAnalyseSynthese[]> {
    return this.repo.create<SocieteConfigAnalyseSynthese[]>('societe-config-analyse-synthese/handle-many', configs);
  }
}
