import { ComposantTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { composantTemplateReducersGeneratedFunctions } from './composant-template-generated.reducer';

const composantTemplateReducersFunctions = [...composantTemplateReducersGeneratedFunctions];

const composantTemplateReducer = createReducer(
  ComposantTemplateState.initialState,
  ...composantTemplateReducersFunctions
);

export function reducer(state: ComposantTemplateState.IState | undefined, action: Action) {
  return composantTemplateReducer(state, action);
}
