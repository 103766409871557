import { GridErrorSocieteTerritoireActions } from '@get/store/actions';
import { GridErrorSocieteTerritoireState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorSocieteTerritoireState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorSocieteTerritoireState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorSocieteTerritoireState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorSocieteTerritoireActions.addOneGridErrorSocieteTerritoire,
    (state: GridErrorSocieteTerritoireState.IState, { gridErrorSocieteTerritoire }) => {
      return GridErrorSocieteTerritoireState.adapter.upsertMany(
        [gridErrorSocieteTerritoire],
        setLoadingsState(state, false)
      );
    }
  ),
  on(
    GridErrorSocieteTerritoireActions.deleteOneGridErrorSocieteTerritoireSuccess,
    (state: GridErrorSocieteTerritoireState.IState, { idGridError }) =>
      GridErrorSocieteTerritoireState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorSocieteTerritoireState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorSocieteTerritoireState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
