import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { CampagneApiService } from '@get/store/api-services';
import { CampagneGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCampagne } from '@get/store/configs/normalization';
import { CampagneSelectors } from '@get/store/selectors';
import { CampagneRelationsIds } from '@get/store/ids-interfaces';
import { SocieteGeneratedActions } from '@get/store/actions';
import { PatrimoineGeneratedActions } from '@get/store/actions';
import { Patrimoine } from '@get/api-interfaces';
import { CampagneUserGeneratedActions } from '@get/store/actions';
import { CampagneUser } from '@get/api-interfaces';
import { UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { CampagnePatrimoineGeneratedActions } from '@get/store/actions';
import { CampagnePatrimoine } from '@get/api-interfaces';
import { CampagneSocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { CampagneSocieteCaracteristique } from '@get/api-interfaces';

export function getDefaultAddCampagneActions(campagne: CampagneEntityState, ids?: CampagneRelationsIds): Action[] {
  const actions: Action[] = [CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert({ campagnes: [campagne] })];

  if (ids?.societe) {
    actions.push(
      SocieteGeneratedActions.addManyCampagneSuccess({
        idSociete: ids.societe,
        idCampagnes: [campagne.idCampagne]
      })
    );
    actions.push(
      CampagneGeneratedActions.addSocieteSuccess({
        idCampagne: campagne.idCampagne,
        idSociete: ids.societe
      })
    );
  }

  if (ids?.patrimoines) {
    if (!Array.isArray(ids.patrimoines)) {
      actions.push(
        PatrimoineGeneratedActions.upsertOnePatrimoine({
          patrimoine: {
            idCampagne: campagne.idCampagne,
            idPatrimoine: ids.patrimoines as number
          } as Patrimoine & any
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyPatrimoineSuccess({
          idCampagne: campagne.idCampagne,
          idPatrimoines: [ids.patrimoines as number]
        })
      );
    } else {
      actions.push(
        PatrimoineGeneratedActions.upsertManyPatrimoines({
          patrimoines: (ids.patrimoines as number[]).map(
            (idPatrimoine: number) => ({
              idCampagne: campagne.idCampagne,
              idPatrimoine
            })
          ) as Patrimoine[] & any[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyPatrimoineSuccess({
          idCampagne: campagne.idCampagne,
          idPatrimoines: ids.patrimoines as number[]
        })
      );
    }
  }

  if (ids?.campagneUsers) {
    if (!Array.isArray(ids.campagneUsers)) {
      actions.push(
        CampagneUserGeneratedActions.upsertOneCampagneUser({
          campagneUser: {
            idCampagne: campagne.idCampagne,
            idCampagneUser: ids.campagneUsers as number
          } as CampagneUser
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagneUserSuccess({
          idCampagne: campagne.idCampagne,
          idCampagneUsers: [ids.campagneUsers as number]
        })
      );
    } else {
      actions.push(
        CampagneUserGeneratedActions.upsertManyCampagneUsers({
          campagneUsers: (ids.campagneUsers as number[]).map(
            (idCampagneUser: number) => ({
              idCampagne: campagne.idCampagne,
              idCampagneUser
            })
          ) as CampagneUser[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagneUserSuccess({
          idCampagne: campagne.idCampagne,
          idCampagneUsers: ids.campagneUsers as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        UserGeneratedActions.upsertOneUser({
          user: {
            idCampagne: campagne.idCampagne,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyUserSuccess({
          idCampagne: campagne.idCampagne,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        UserGeneratedActions.upsertManyUsers({
          users: (ids.users as number[]).map(
            (idUser: number) => ({
              idCampagne: campagne.idCampagne,
              idUser
            })
          ) as User[] & any[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyUserSuccess({
          idCampagne: campagne.idCampagne,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idCampagne: campagne.idCampagne,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique & any
        })
      );
      actions.push(
        CampagneGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idCampagne: campagne.idCampagne,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idCampagne: campagne.idCampagne,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[] & any[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idCampagne: campagne.idCampagne,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.campagnePatrimoines) {
    if (!Array.isArray(ids.campagnePatrimoines)) {
      actions.push(
        CampagnePatrimoineGeneratedActions.upsertOneCampagnePatrimoine({
          campagnePatrimoine: {
            idCampagne: campagne.idCampagne,
            idCampagnePatrimoine: ids.campagnePatrimoines as number
          } as CampagnePatrimoine
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagnePatrimoineSuccess({
          idCampagne: campagne.idCampagne,
          idCampagnePatrimoines: [ids.campagnePatrimoines as number]
        })
      );
    } else {
      actions.push(
        CampagnePatrimoineGeneratedActions.upsertManyCampagnePatrimoines({
          campagnePatrimoines: (ids.campagnePatrimoines as number[]).map(
            (idCampagnePatrimoine: number) => ({
              idCampagne: campagne.idCampagne,
              idCampagnePatrimoine
            })
          ) as CampagnePatrimoine[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagnePatrimoineSuccess({
          idCampagne: campagne.idCampagne,
          idCampagnePatrimoines: ids.campagnePatrimoines as number[]
        })
      );
    }
  }

  if (ids?.campagneSocieteCaracteristiques) {
    if (!Array.isArray(ids.campagneSocieteCaracteristiques)) {
      actions.push(
        CampagneSocieteCaracteristiqueGeneratedActions.upsertOneCampagneSocieteCaracteristique({
          campagneSocieteCaracteristique: {
            idCampagne: campagne.idCampagne,
            idCampagneSocieteCaracteristique: ids.campagneSocieteCaracteristiques as number
          } as CampagneSocieteCaracteristique
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
          idCampagne: campagne.idCampagne,
          idCampagneSocieteCaracteristiques: [ids.campagneSocieteCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        CampagneSocieteCaracteristiqueGeneratedActions.upsertManyCampagneSocieteCaracteristiques({
          campagneSocieteCaracteristiques: (ids.campagneSocieteCaracteristiques as number[]).map(
            (idCampagneSocieteCaracteristique: number) => ({
              idCampagne: campagne.idCampagne,
              idCampagneSocieteCaracteristique
            })
          ) as CampagneSocieteCaracteristique[]
        })
      );
      actions.push(
        CampagneGeneratedActions.addManyCampagneSocieteCaracteristiqueSuccess({
          idCampagne: campagne.idCampagne,
          idCampagneSocieteCaracteristiques: ids.campagneSocieteCaracteristiques as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCampagneActions(campagne: CampagneEntityState): Action[] {
  const actions: Action[] = [CampagneGeneratedActions.deleteOneCampagneSuccess({ idCampagne: campagne.idCampagne })];

  if (campagne.societe) {
    actions.push(
      SocieteGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idSocietes: [campagne.societe as number]
      })
    );
  }

  if (campagne.patrimoines) {
    actions.push(
      PatrimoineGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idPatrimoines: campagne.patrimoines as number[]
      })
    );
  }

  if (campagne.campagneUsers) {
    actions.push(
      CampagneUserGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idCampagneUsers: campagne.campagneUsers as number[]
      })
    );
  }

  if (campagne.users) {
    actions.push(
      UserGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idUsers: campagne.users as number[]
      })
    );
  }

  if (campagne.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idSocieteCaracteristiques: campagne.societeCaracteristiques as number[]
      })
    );
  }

  if (campagne.campagnePatrimoines) {
    actions.push(
      CampagnePatrimoineGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idCampagnePatrimoines: campagne.campagnePatrimoines as number[]
      })
    );
  }

  if (campagne.campagneSocieteCaracteristiques) {
    actions.push(
      CampagneSocieteCaracteristiqueGeneratedActions.deleteManyCampagneSuccess({
        idCampagnes: [campagne.idCampagne],
        idCampagneSocieteCaracteristiques: campagne.campagneSocieteCaracteristiques as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCampagneEffects {
  constructor(
    protected actions$: Actions,
    protected campagneApiService: CampagneApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCampagnes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneGeneratedActions.getManyCampagnes),
      switchMap(({ params }) =>
        this.campagneApiService.getCampagnes(params).pipe(
          map((campagnes: Campagne[]) => {
            return CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert({ campagnes });
          }),
          catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
        )
      )
    );
  });

  getOneCampagne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneGeneratedActions.getOneCampagne),
      switchMap(idCampagne =>
        this.campagneApiService.getCampagne(idCampagne).pipe(
          map((campagne: Campagne) => {
            return CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert({ campagnes: [campagne] });
          }),
          catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
        )
      )
    );
  });

  upsertOneCampagne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneGeneratedActions.upsertOneCampagne),
      concatMap(
        ({
          campagne,
          ids
        }: {
          campagne: Partial<Campagne>;
          ids?: CampagneRelationsIds;
        }) => {
          if (campagne.idCampagne) {
            return this.campagneApiService.updateCampagne(campagne).pipe(
              map((campagneReturned: Campagne) => {
                return CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert({ campagnes: [campagneReturned] });
              }),
              catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
            );
          } else {
            return this.campagneApiService.addCampagne(campagne).pipe(
              mergeMap((campagneReturned: Campagne) => getDefaultAddCampagneActions(campagneReturned, ids)),
              catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCampagne$ = createEffect(() => {
    const selectCampagneState$ = this.store$.select(CampagneSelectors.selectCampagneState);
    return this.actions$.pipe(
      ofType(CampagneGeneratedActions.deleteOneCampagne),
      withLatestFrom(selectCampagneState$),
      concatMap(([{ idCampagne }, state]) =>
        this.campagneApiService.deleteCampagne(idCampagne).pipe(
          mergeMap(_success => getDefaultDeleteCampagneActions(state.entities[idCampagne] as CampagneEntityState)),
          catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCampagnesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert),
      concatMap(({ campagnes }) => {
        const actions: Action[] = getActionsToNormalizeCampagne(campagnes, StoreActionType.upsert);
        return [getMultiAction(actions, '[Campagne] Normalization After Upsert Success')];
      })
    );
  });
}
