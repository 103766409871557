import { Injectable } from '@angular/core';
import { SocieteComposant, SocieteComposantToHandle } from '@get/api-interfaces';

import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedSocieteComposantApiService } from './societe-composant-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteComposantApiService extends GeneratedSocieteComposantApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getSocieteComposantDescriptions(params?: any): Observable<SocieteComposant[]> {
    return this.repo.getData<SocieteComposant[]>('societe-composant/descriptions', params);
  }

  public handleManySocieteComposants(params: SocieteComposantToHandle): Observable<SocieteComposant[]> {
    return this.repo.create('societe-composant/handle-many', params);
  }

  public duplicateSocieteComposantWithCaracs(params: {
    idSocieteTarget: number;
    idsSocieteComposant?: number[];
    idSocieteSource?: number;
  }): Observable<string[]> {
    return this.repo.create('societe-composant/duplicate-societe-composant-and-societe-carac-and-list', params);
  }
}
