import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedCaracteristiqueChoixTemplateApiService } from './caracteristique-choix-template-api-generated.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CaracteristiqueChoixTemplateApiService extends GeneratedCaracteristiqueChoixTemplateApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public handleManyCaracteristiqueChoixTemplate(params: any): Observable<any> {
    return this.repo.create<any>('caracteristique-choix-template/handle-many', params);
  }
}
