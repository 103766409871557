import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComposantGroupeApiService } from '@get/store/api-services';
import { GeneratedComposantGroupeEffects } from './composant-groupe-generated.effects';

@Injectable()
export class ComposantGroupeEffects extends GeneratedComposantGroupeEffects {
  constructor(actions$: Actions, composantGroupeApiService: ComposantGroupeApiService, store$: Store<AppState>) {
    super(actions$, composantGroupeApiService, store$);
  }
}
