import { createActionGroup, props } from '@ngrx/store';

export const NewsletterActions = createActionGroup({
  source: 'Newsletter',
  events: {
    'Get Many Newsletters By Organisation': props<{ params: any }>(),
    'Get Newsletters Unread': props<{ params: any }>(),
    'Mark As Read': props<{ idsNewsletter: number[] }>()
  }
});
