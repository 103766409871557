import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PatrimoineHierarchieTemplateApiService } from '@get/store/api-services';
import { GeneratedPatrimoineHierarchieTemplateEffects } from './patrimoine-hierarchie-template-generated.effects';

@Injectable()
export class PatrimoineHierarchieTemplateEffects extends GeneratedPatrimoineHierarchieTemplateEffects {
  constructor(
    actions$: Actions,
    patrimoineHierarchieTemplateApiService: PatrimoineHierarchieTemplateApiService,
    store$: Store<AppState>
  ) {
    super(actions$, patrimoineHierarchieTemplateApiService, store$);
  }
}
