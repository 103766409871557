import { Injectable } from '@angular/core';
import { LoginData } from '@get/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private isLocal = false;

  public getLocal(key: string, parse = true): any {
    return parse ? JSON.parse(localStorage.getItem(key) as string) : (localStorage.getItem(key) as string);
  }

  public getSession(key: string): any {
    return JSON.parse(sessionStorage.getItem(key) as string);
  }

  public getStorage(key: string): any {
    return this.isLocal ? this.getLocal(key) : this.getSession(key);
  }

  public setLocal(key: string, data: any): void {
    return localStorage.setItem(key, JSON.stringify(data));
  }

  public setSession(key: string, data: any): void {
    return sessionStorage.setItem(key, JSON.stringify(data));
  }

  public setStorage(key: string, data: any): void {
    this.isLocal ? this.setLocal(key, data) : this.setSession(key, data);
  }

  public clearLocal(): void {
    localStorage.clear();
  }

  public clearSession(): void {
    sessionStorage.clear();
  }

  public clearStorage(): void {
    this.isLocal ? this.clearLocal() : this.clearSession();
  }

  public clearAll(): void {
    this.clearLocal();
    this.clearSession();
  }

  public removeLocal(key: string): void {
    localStorage.removeItem(key);
  }

  public removeSession(key: string): void {
    sessionStorage.removeItem(key);
  }

  public removeStorage(key: string): void {
    this.isLocal ? this.removeStorage(key) : this.removeSession(key);
  }

  public removeAll(key: string): void {
    this.removeLocal(key);
    this.removeSession(key);
  }

  public changeStorage(isLocal: boolean): void {
    this.isLocal = isLocal;
  }

  public isLogged(): LoginData | null {
    let loginData: LoginData = this.getSession('login');
    if (loginData && loginData.token) {
      this.changeStorage(false);
      return loginData;
    }
    loginData = this.getLocal('login');
    if (loginData && loginData.token) {
      this.changeStorage(true);
      return loginData;
    }
    return null;
  }
}
