import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '@get/shared/information';
import { TypeModalEnum } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private matDialog: MatDialog) {}

  public showError(message: string): void {
    this.matDialog.open(InformationComponent, {
      width: '250px',
      maxWidth: '100%',
      data: {
        title: 'Attention',
        question: message,
        theme: TypeModalEnum.information
      }
    });
  }
}
