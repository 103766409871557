import { SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeConfigAnalyseSyntheseSocieteCaracteristiqueReducersGeneratedFunctions } from './societe-config-analyse-synthese-societe-caracteristique-generated.reducer';

const societeConfigAnalyseSyntheseSocieteCaracteristiqueReducersFunctions = [
  ...societeConfigAnalyseSyntheseSocieteCaracteristiqueReducersGeneratedFunctions
];

const societeConfigAnalyseSyntheseSocieteCaracteristiqueReducer = createReducer(
  SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.initialState,
  ...societeConfigAnalyseSyntheseSocieteCaracteristiqueReducersFunctions
);

export function reducer(
  state: SocieteConfigAnalyseSyntheseSocieteCaracteristiqueState.IState | undefined,
  action: Action
) {
  return societeConfigAnalyseSyntheseSocieteCaracteristiqueReducer(state, action);
}
