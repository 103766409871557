import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedSocietePatrimoineHierarchieAncetreApiService } from './societe-patrimoine-hierarchie-ancetre-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocietePatrimoineHierarchieAncetreApiService extends GeneratedSocietePatrimoineHierarchieAncetreApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
