import { JwtPayload } from '@get/api-interfaces';

export function parseJwt(token: string): Partial<JwtPayload> {
  if (token) {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      return {};
    }
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // TODO: Check deprecated (Buffer.from ne fonctionne pas sur angular)
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    if (jsonPayload[jsonPayload.length - 1] !== '}') {
      return {};
    }
    return JSON.parse(jsonPayload);
  }
  return {};
}
