import { SocieteEspaceFamille } from '@get/api-interfaces';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const SocieteEspaceFamilleActions = createActionGroup({
  source: 'Societe Espace Famille',
  events: {
    'Upsert One Societe Espace Famille With Societe Composant': props<{
      societeEspaceFamille: Partial<SocieteEspaceFamille>;
      ids?: SocieteEspaceFamilleRelationsIds;
    }>(),
    'Delete One Societe Espace Famille With Societe Espace': props<{ idSocieteEspaceFamille: number }>()
  }
});
