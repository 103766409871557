import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedSocieteTerritoirePatrimoineApiService } from './societe-territoire-patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteTerritoirePatrimoineApiService extends GeneratedSocieteTerritoirePatrimoineApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
