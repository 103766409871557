import { SocieteCaracteristiqueState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeCaracteristiqueReducersGeneratedFunctions } from './societe-caracteristique-generated.reducer';

const societeCaracteristiqueReducersFunctions = [...societeCaracteristiqueReducersGeneratedFunctions];

const societeCaracteristiqueReducer = createReducer(
  SocieteCaracteristiqueState.initialState,
  ...societeCaracteristiqueReducersFunctions
);

export function reducer(state: SocieteCaracteristiqueState.IState | undefined, action: Action) {
  return societeCaracteristiqueReducer(state, action);
}
