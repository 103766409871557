import { Injectable } from '@angular/core';
import { Societe } from '@get/api-interfaces';
import { appState, AppState, clearState, GeneratedAppState } from '@get/store/configs/reducers';
import { SocieteSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { arraysAreEqual } from '@utils';
import { distinctUntilChanged, filter, map, Observable, switchMap } from 'rxjs';
import { GeneratedSocieteService } from './societe-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteService extends GeneratedSocieteService {
  public currentSociete?: Societe;

  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  setCurrentSociete(societe: Societe) {
    // Si ancienne valeur initialisée et changement de société, alors update active societe pour avoir le bon id
    let shouldUpdateActiveSocietes = false;
    if (this.currentSociete && this.currentSociete?.idSociete !== societe?.idSociete) {
      shouldUpdateActiveSocietes = true;
    }
    this.currentSociete = societe;
    if (societe && shouldUpdateActiveSocietes) {
      this.setActiveSocietes([societe.idSociete]);
    }
  }

  getCurrentSociete(): Societe | undefined {
    return this.currentSociete;
  }

  public selectAllActiveSocietesIds(schema: SelectSchema = {}): Observable<number[]> {
    return this.store$.pipe(select(SocieteSelectors.selectActiveSocietes(schema))).pipe(
      switchMap(({ societes }: { societes: Societe[] }) => {
        return this.getReady(schema).pipe(map(() => societes));
      }),
      map((societes: Societe[]) => this.mapToSocietesIds(societes)),
      filter(ids => !!ids?.length),
      distinctUntilChanged(this.isArrayChange())
    );
  }

  public switchSociete(societe: Societe): void {
    const states = this.findAllStateNameToClear() as (keyof GeneratedAppState)[];
    this.store$.dispatch(clearState({ states }));
    this.setActiveSocietes([societe.idSociete]);
  }

  findAllStateNameToClear() {
    const stateToKeep = [
      'caracteristiqueTemplate',
      'caracteristiqueChoixTemplate',
      'composantGroupe',
      'composantTemplate',
      'droit',
      'organisation',
      'patrimoineHierarchieTemplate',
      'societe',
      'user',
      'fichier'
    ];

    const storeToclear = Object.keys(appState).filter(
      key => !this.filterByRegex(key) && !stateToKeep.some(k => k === key)
    );
    return storeToclear;
  }

  filterByRegex(stateKey: string): boolean {
    const regexRules = '^organisation[a-zA-Z]*|^societe[a-zA-Z]*';
    return new RegExp(regexRules).test(stateKey);
  }

  mapToSocietesIds(societes: Societe[]): number[] {
    return societes.map(societe => societe?.idSociete).filter(it => it);
  }

  private isArrayChange(): (previous: number[], current: number[]) => boolean {
    return (a, b) => {
      const idsA = [...a].sort();
      const idsB = [...b].sort();
      return arraysAreEqual(idsA, idsB);
    };
  }
}
