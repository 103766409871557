import { Injectable } from '@angular/core';
import { StoreActionType } from '@enums';
import { DynamicType, SocieteCaracteristiqueChoix, SocieteCaracteristiqueChoixEntityState } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixActions, SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueChoixApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { getActionsToNormalizeSocieteCaracteristiqueChoix } from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueChoixSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  GeneratedSocieteCaracteristiqueChoixEffects,
  getDefaultAddSocieteCaracteristiqueChoixActions,
  getDefaultDeleteSocieteCaracteristiqueChoixActions
} from './societe-caracteristique-choix-generated.effects';

@Injectable()
export class SocieteCaracteristiqueChoixEffects extends GeneratedSocieteCaracteristiqueChoixEffects {
  constructor(
    actions$: Actions,
    societeCaracteristiqueChoixApiService: SocieteCaracteristiqueChoixApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeCaracteristiqueChoixApiService, store$);
  }

  handleManySocieteCaracteristiqueChoix$ = createEffect(() => {
    const selectSocieteCaracteristiqueChoixState$ = this.store$.select(
      SocieteCaracteristiqueChoixSelectors.selectSocieteCaracteristiqueChoixState
    );
    return this.actions$.pipe(
      ofType(SocieteCaracteristiqueChoixActions.handleManySocieteCaracteristiqueChoix),
      withLatestFrom(selectSocieteCaracteristiqueChoixState$),
      switchMap(([params, state]) => {
        return this.societeCaracteristiqueChoixApiService.handleManySocieteCaracteristiqueChoix(params).pipe(
          mergeMap((updateObj: SocieteCaracteristiqueChoix[]) => {
            const actions: Action[] = [];

            if (params.itemsToDelete?.length) {
              for (let i = 0; i < params.itemsToDelete.length; i++) {
                const idSocieteCaracteristiqueChoix = params.itemsToDelete[i];
                actions.push(
                  ...getDefaultDeleteSocieteCaracteristiqueChoixActions(
                    state.entities[idSocieteCaracteristiqueChoix] as SocieteCaracteristiqueChoixEntityState
                  )
                );
              }
            }

            const itemsToUpdateObj = params.itemsToUpdate.reduce((acc, curr) => {
              acc[curr.idSocieteCaracteristiqueChoix] = curr;
              return acc;
            }, {} as DynamicType<SocieteCaracteristiqueChoix>);

            const updatedArray = [];
            for (let i = 0; i < updateObj.length; i++) {
              const idSocieteCaracteristiqueChoix = updateObj[i].idSocieteCaracteristiqueChoix;
              if (itemsToUpdateObj[idSocieteCaracteristiqueChoix]) {
                updatedArray.push(updateObj[i]);
              } else {
                // Laisser les 2 actions cohabiter car la seconde ne trigger pas la normalization correctement
                // (malgré le fait que le tableau contienne l'action)
                actions.push(
                  ...getActionsToNormalizeSocieteCaracteristiqueChoix([updateObj[i]], StoreActionType.upsert)
                );
                actions.push(
                  ...getDefaultAddSocieteCaracteristiqueChoixActions(updateObj[i], {
                    societeCaracteristique: updateObj[i].idSocieteCaracteristique
                  })
                );
              }
            }
            if (updatedArray.length) {
              actions.push(...getActionsToNormalizeSocieteCaracteristiqueChoix(updatedArray, StoreActionType.upsert));
            }

            if (actions.length > 1) {
              return [
                getMultiAction(actions, SocieteCaracteristiqueChoixActions.handleManySocieteCaracteristiqueChoix.type)
              ];
            }
            return actions;
          }),
          catchError(error =>
            of(SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure({ error }))
          )
        );
      })
    );
  });
}
