import { SocieteProfilState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeProfilReducersGeneratedFunctions } from './societe-profil-generated.reducer';

const societeProfilReducersFunctions = [...societeProfilReducersGeneratedFunctions];

const societeProfilReducer = createReducer(SocieteProfilState.initialState, ...societeProfilReducersFunctions);

export function reducer(state: SocieteProfilState.IState | undefined, action: Action) {
  return societeProfilReducer(state, action);
}
