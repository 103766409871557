import { ValidatorFn, AbstractControl } from '@angular/forms';

export function passwordConfirmValidator(passwordInput: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup = control.parent;
    if (formGroup) {
      const password = control.parent.get(passwordInput)?.value;
      const isSame = control.value && password === control.value;
      return isSame ? null : { passwordConfirm: true };
    } else {
      return null;
    }
  };
}
