import { ValeurComposantState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurComposantReducersGeneratedFunctions } from './valeur-composant-generated.reducer';

const valeurComposantReducersFunctions = [...valeurComposantReducersGeneratedFunctions];

const valeurComposantReducer = createReducer(ValeurComposantState.initialState, ...valeurComposantReducersFunctions);

export function reducer(state: ValeurComposantState.IState | undefined, action: Action) {
  return valeurComposantReducer(state, action);
}
