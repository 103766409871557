import { ComposantAttenduState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { composantAttenduReducersGeneratedFunctions } from './composant-attendu-generated.reducer';

const composantAttenduReducersFunctions = [...composantAttenduReducersGeneratedFunctions];

const composantAttenduReducer = createReducer(ComposantAttenduState.initialState, ...composantAttenduReducersFunctions);

export function reducer(state: ComposantAttenduState.IState | undefined, action: Action) {
  return composantAttenduReducer(state, action);
}
