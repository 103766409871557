import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteProfilApiService } from '@get/store/api-services';
import { GeneratedSocieteProfilEffects } from './societe-profil-generated.effects';

@Injectable()
export class SocieteProfilEffects extends GeneratedSocieteProfilEffects {
  constructor(actions$: Actions, societeProfilApiService: SocieteProfilApiService, store$: Store<AppState>) {
    super(actions$, societeProfilApiService, store$);
  }
}
