import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteComposantRattachementApiService } from '@get/store/api-services';
import { GeneratedSocieteComposantRattachementEffects } from './societe-composant-rattachement-generated.effects';

@Injectable()
export class SocieteComposantRattachementEffects extends GeneratedSocieteComposantRattachementEffects {
  constructor(
    actions$: Actions,
    societeComposantRattachementApiService: SocieteComposantRattachementApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeComposantRattachementApiService, store$);
  }
}
