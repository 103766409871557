import { Injectable } from '@angular/core';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieUpdate } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable, firstValueFrom } from 'rxjs';
import { GeneratedSocietePatrimoineHierarchieApiService } from './societe-patrimoine-hierarchie-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocietePatrimoineHierarchieApiService extends GeneratedSocietePatrimoineHierarchieApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getPatrimoinesToConsultList(params?: any): Observable<SocietePatrimoineHierarchie[]> {
    return this.repo.getData<SocietePatrimoineHierarchie[]>('societe-patrimoine-hierarchie/consult-list', params);
  }

  public getSocietePatrimoineHierarchiesWithAncetres(params?: any): Observable<SocietePatrimoineHierarchie[]> {
    return this.repo.getData<SocietePatrimoineHierarchie[]>('societe-patrimoine-hierarchie/ancetres', params);
  }

  public authorizationToDeleteSocietePatrimoineHierarchie(idSocietePatrimoineHierarchie: number): Promise<boolean> {
    return firstValueFrom(
      this.repo.getData('societe-patrimoine-hierarchie/authorization-to-delete/' + +idSocietePatrimoineHierarchie)
    );
  }

  public updateSocietePatrimoineHierarchieWithTree(
    societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>
  ): Observable<SocietePatrimoineHierarchieUpdate> {
    return this.repo.update('societe-patrimoine-hierarchie', societePatrimoineHierarchie);
  }

  public duplicateSocietePatrimoineHierarchieWithCaracs(params: {
    idSocieteTarget: number;
    idsSocietePatrimoineHierarchie?: number[];
    idSocieteSource?: number;
  }): Observable<string[]> {
    return this.repo.create(
      'societe-patrimoine-hierarchie/duplicate-societe-patrimoine-hierarchie-and-societe-carac-and-list',
      params
    );
  }
}
