import { PatrimoineAncetreState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { patrimoineAncetreReducersGeneratedFunctions } from './patrimoine-ancetre-generated.reducer';

const patrimoineAncetreReducersFunctions = [...patrimoineAncetreReducersGeneratedFunctions];

const patrimoineAncetreReducer = createReducer(
  PatrimoineAncetreState.initialState,
  ...patrimoineAncetreReducersFunctions
);

export function reducer(state: PatrimoineAncetreState.IState | undefined, action: Action) {
  return patrimoineAncetreReducer(state, action);
}
