import { SocieteCaracteristiqueChoixState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeCaracteristiqueChoixReducersGeneratedFunctions } from './societe-caracteristique-choix-generated.reducer';

const societeCaracteristiqueChoixReducersFunctions = [...societeCaracteristiqueChoixReducersGeneratedFunctions];

const societeCaracteristiqueChoixReducer = createReducer(
  SocieteCaracteristiqueChoixState.initialState,
  ...societeCaracteristiqueChoixReducersFunctions
);

export function reducer(state: SocieteCaracteristiqueChoixState.IState | undefined, action: Action) {
  return societeCaracteristiqueChoixReducer(state, action);
}
