import { SocieteEspace, SocieteEspaceEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { hydrateAll, selectSocieteEspacesEntities, societeEspaceRelations } from './societe-espace-generated.selectors';

export * from './societe-espace-generated.selectors';

const filterSocieteEspaceFamilles = (societeEspaceFamilles: Dictionary<SocieteEspaceEntityState>) =>
  Object.keys(societeEspaceFamilles)
    .filter(idSocieteEspaces => !societeEspaceFamilles[idSocieteEspaces]?.idSocieteEspaceFamille)
    .reduce(
      (acc, idSocieteEspaces) =>
        Object.assign(acc, {
          [idSocieteEspaces]: societeEspaceFamilles[idSocieteEspaces]
        }),
      {}
    );

const societeEspaceFamillesInObject = (societeEspaces: Dictionary<SocieteEspaceEntityState>) => ({
  societeEspaces: filterSocieteEspaceFamilles(societeEspaces)
});

const selectSocieteEspaceFamillesEntitiesDictionary = createSelector(
  selectSocieteEspacesEntities,
  societeEspaceFamillesInObject
);

const selectAllSocieteEspaceFamillesObject = createSelector(selectSocieteEspacesEntities, societeEspaces => {
  return hydrateAll({
    societeEspaces: filterSocieteEspaceFamilles(societeEspaces)
  });
});

const selectAllSocieteEspaceFamillesSelectors: Dictionary<Selector> = {};
export function selectAllSocieteEspaceFamilles(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteEspace>(
      schema,
      selectAllSocieteEspaceFamillesSelectors,
      selectSocieteEspaceFamillesEntitiesDictionary,
      getRelationSelectors,
      societeEspaceRelations,
      hydrateAll,
      'societeEspace'
    );
  } else {
    return selectAllSocieteEspaceFamillesObject;
  }
}
