import { CaracteristiqueChoixTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { caracteristiqueChoixTemplateReducersGeneratedFunctions } from './caracteristique-choix-template-generated.reducer';

const caracteristiqueChoixTemplateReducersFunctions = [...caracteristiqueChoixTemplateReducersGeneratedFunctions];

const caracteristiqueChoixTemplateReducer = createReducer(
  CaracteristiqueChoixTemplateState.initialState,
  ...caracteristiqueChoixTemplateReducersFunctions
);

export function reducer(state: CaracteristiqueChoixTemplateState.IState | undefined, action: Action) {
  return caracteristiqueChoixTemplateReducer(state, action);
}
