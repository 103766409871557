import { SocieteEspaceFamilleState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeEspaceFamilleReducersGeneratedFunctions } from './societe-espace-famille-generated.reducer';

const societeEspaceFamilleReducersFunctions = [...societeEspaceFamilleReducersGeneratedFunctions];

const societeEspaceFamilleReducer = createReducer(
  SocieteEspaceFamilleState.initialState,
  ...societeEspaceFamilleReducersFunctions
);

export function reducer(state: SocieteEspaceFamilleState.IState | undefined, action: Action) {
  return societeEspaceFamilleReducer(state, action);
}
