import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CaracteristiqueTemplateApiService } from '@get/store/api-services';
import { GeneratedCaracteristiqueTemplateEffects } from './caracteristique-template-generated.effects';

@Injectable()
export class CaracteristiqueTemplateEffects extends GeneratedCaracteristiqueTemplateEffects {
  constructor(
    actions$: Actions,
    caracteristiqueTemplateApiService: CaracteristiqueTemplateApiService,
    store$: Store<AppState>
  ) {
    super(actions$, caracteristiqueTemplateApiService, store$);
  }
}
