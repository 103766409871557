import { SocieteState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeReducersGeneratedFunctions } from './societe-generated.reducer';

const societeReducersFunctions = [...societeReducersGeneratedFunctions];

const societeReducer = createReducer(SocieteState.initialState, ...societeReducersFunctions);

export function reducer(state: SocieteState.IState | undefined, action: Action) {
  return societeReducer(state, action);
}
