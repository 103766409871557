import { ComposantGroupeState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { composantGroupeReducersGeneratedFunctions } from './composant-groupe-generated.reducer';

const composantGroupeReducersFunctions = [...composantGroupeReducersGeneratedFunctions];

const composantGroupeReducer = createReducer(ComposantGroupeState.initialState, ...composantGroupeReducersFunctions);

export function reducer(state: ComposantGroupeState.IState | undefined, action: Action) {
  return composantGroupeReducer(state, action);
}
