import { Injectable } from '@angular/core';
import { SocieteCaracteristiqueChoixUpdate } from '@get/api-interfaces';
import { SocieteCaracteristiqueChoixActions, SocieteCaracteristiqueChoixGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedSocieteCaracteristiqueChoixService } from './societe-caracteristique-choix-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteCaracteristiqueChoixService extends GeneratedSocieteCaracteristiqueChoixService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public handleManySocieteCaracteristiqueChoix(
    params: SocieteCaracteristiqueChoixUpdate,
    getResult?: boolean
  ): void | Observable<any> {
    this.store$.dispatch(SocieteCaracteristiqueChoixActions.handleManySocieteCaracteristiqueChoix(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteCaracteristiqueChoixGeneratedActions.normalizeManySocieteCaracteristiqueChoicesAfterUpsert,
        SocieteCaracteristiqueChoixGeneratedActions.societeCaracteristiqueChoicesFailure,
        true
      );
    }
  }
}
