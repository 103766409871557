import { UserNewsletterState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userNewsletterReducersGeneratedFunctions } from './user-newsletter-generated.reducer';

const userNewsletterReducersFunctions = [...userNewsletterReducersGeneratedFunctions];

const userNewsletterReducer = createReducer(UserNewsletterState.initialState, ...userNewsletterReducersFunctions);

export function reducer(state: UserNewsletterState.IState | undefined, action: Action) {
  return userNewsletterReducer(state, action);
}
