import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedSocieteCaracteristiqueChoixApiService } from './societe-caracteristique-choix-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteCaracteristiqueChoixApiService extends GeneratedSocieteCaracteristiqueChoixApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public handleManySocieteCaracteristiqueChoix(params: any): Observable<any> {
    return this.repo.create<any>('societe-caracteristique-choix/handle-many', params);
  }
}
