import { SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { Dictionary } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { societePatrimoineHierarchieReducersGeneratedFunctions } from './societe-patrimoine-hierarchie-generated.reducer';
import { SocietePatrimoineHierarchieActions } from '@get/store/actions';
import { SocietePatrimoineHierarchieState } from '@get/store/states';

const societePatrimoineHierarchieReducersFunctions = [...societePatrimoineHierarchieReducersGeneratedFunctions];

societePatrimoineHierarchieReducersFunctions.push(
  on(
    SocietePatrimoineHierarchieActions.deleteManyHierarchieAncetresWithAliasSuccess,
    (
      state: SocietePatrimoineHierarchieState.IState,
      { idHierarchieAncetres: idHierarchieAncetres, idSocietePatrimoineHierarchies }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              hierarchieAncetres: (
                state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres as number[]
              )?.filter(
                (idSocietePatrimoineHierarchieAncetre: number) =>
                  !idHierarchieAncetres.some((id: number) => id === idSocietePatrimoineHierarchieAncetre)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  )
);
societePatrimoineHierarchieReducersFunctions.push(
  on(
    SocietePatrimoineHierarchieActions.deleteManyHierarchieDescendantsWithAliasSuccess,
    (
      state: SocietePatrimoineHierarchieState.IState,
      { idHierarchieDescendants: idHierarchieDescendants, idSocietePatrimoineHierarchies }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietePatrimoineHierarchies.reduce((entities, idSocietePatrimoineHierarchie) => {
            if (!state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants) {
              return entities;
            }
            entities[idSocietePatrimoineHierarchie] = {
              ...state.entities[idSocietePatrimoineHierarchie],
              hierarchieDescendants: (
                state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants as number[]
              )?.filter(
                (idSocietePatrimoineHierarchieAncetre: number) =>
                  !idHierarchieDescendants.some((id: number) => id === idSocietePatrimoineHierarchieAncetre)
              )
            } as SocietePatrimoineHierarchieEntityState;
            return entities;
          }, {} as Dictionary<SocietePatrimoineHierarchieEntityState>)
        }
      };
    }
  )
);
societePatrimoineHierarchieReducersFunctions.push(
  on(
    SocietePatrimoineHierarchieActions.addManyHierarchieAncetresWithAliasSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idHierarchieAncetres }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societePatrimoineHierarchieAncetres =
        (state.entities[idSocietePatrimoineHierarchie]?.hierarchieAncetres as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            hierarchieAncetres: societePatrimoineHierarchieAncetres.concat(
              idHierarchieAncetres.filter(id => societePatrimoineHierarchieAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  )
);
societePatrimoineHierarchieReducersFunctions.push(
  on(
    SocietePatrimoineHierarchieActions.addManyHierarchieDescendantsWithAliasSuccess,
    (state: SocietePatrimoineHierarchieState.IState, { idSocietePatrimoineHierarchie, idHierarchieDescendants }) => {
      if (!state.entities[idSocietePatrimoineHierarchie]) {
        return state;
      }
      const societePatrimoineHierarchieAncetres =
        (state.entities[idSocietePatrimoineHierarchie]?.hierarchieDescendants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSocietePatrimoineHierarchie]: {
            ...state.entities[idSocietePatrimoineHierarchie],
            hierarchieDescendants: societePatrimoineHierarchieAncetres.concat(
              idHierarchieDescendants.filter(id => societePatrimoineHierarchieAncetres.indexOf(id) < 0)
            )
          }
        }
      };
    }
  )
);

const societePatrimoineHierarchieReducer = createReducer(
  SocietePatrimoineHierarchieState.initialState,
  ...societePatrimoineHierarchieReducersFunctions
);

export function reducer(state: SocietePatrimoineHierarchieState.IState | undefined, action: Action) {
  return societePatrimoineHierarchieReducer(state, action);
}

// TODO: Check pourquoi dans les schematics, dans le reducer generated au niveau de SocietePatrimoineHierarchieActions.deleteManyHierarchieAncetresSuccess
// l'alias n'a pas l'air d'être bien pris en compte, ce qui fait que le delete ne fonctionne pas
