import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedSocieteTerritoireApiService } from './societe-territoire-api-generated.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocieteTerritoireApiService extends GeneratedSocieteTerritoireApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public synchronizeTerritoires(idSociete?: number): Observable<void> {
    return this.repo.create<void>(`societe-territoire/synchronize${idSociete ? '/' + idSociete : ''}`, {});
  }
}
