import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService } from './societe-config-analyse-synthese-societe-caracteristique-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService extends GeneratedSocieteConfigAnalyseSyntheseSocieteCaracteristiqueApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
