import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PatrimoineAncetreApiService } from '@get/store/api-services';
import { GeneratedPatrimoineAncetreEffects } from './patrimoine-ancetre-generated.effects';

@Injectable()
export class PatrimoineAncetreEffects extends GeneratedPatrimoineAncetreEffects {
  constructor(actions$: Actions, patrimoineAncetreApiService: PatrimoineAncetreApiService, store$: Store<AppState>) {
    super(actions$, patrimoineAncetreApiService, store$);
  }
}
