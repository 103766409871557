import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedUserNewsletterApiService } from './user-newsletter-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserNewsletterApiService extends GeneratedUserNewsletterApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
