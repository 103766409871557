import Compressor from 'compressorjs';

export function compressFiles(files: any): Promise<any> {
  return Promise.all(
    files.map(
      (file: any) =>
        new Promise((resolve, reject) => {
          if (file.type === 'image/gif') {
            resolve(file);
          } else {
            new Compressor(file, {
              quality: 0.75,
              maxWidth: 1920,
              maxHeight: 1080,
              convertTypes: ['image/png', 'image/webp'],
              convertSize: 1000000,
              success: result => {
                resolve(
                  result instanceof Blob ? new File([result], (result as any).name, { type: result.type }) : result
                );
              },
              error: err => {
                console.error(err.message);
                reject(err);
              }
            });
          }
        })
    )
  );
}
