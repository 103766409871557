import { SocieteTerritoireUserState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeTerritoireUserReducersGeneratedFunctions } from './societe-territoire-user-generated.reducer';

const societeTerritoireUserReducersFunctions = [...societeTerritoireUserReducersGeneratedFunctions];

const societeTerritoireUserReducer = createReducer(
  SocieteTerritoireUserState.initialState,
  ...societeTerritoireUserReducersFunctions
);

export function reducer(state: SocieteTerritoireUserState.IState | undefined, action: Action) {
  return societeTerritoireUserReducer(state, action);
}
