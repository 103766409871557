import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenInterceptor } from './token.interceptor';
import { HttpErrorInterceptor } from './http-error-interceptor.interceptor';
import { PatrimoineAccessInterceptor } from './patrimoine-access-error-interceptor.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PatrimoineAccessInterceptor,
      multi: true
    }
  ]
})
export class GetCoreInterceptorsModule {}
