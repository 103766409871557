import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedComposantTemplateApiService } from './composant-template-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ComposantTemplateApiService extends GeneratedComposantTemplateApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
