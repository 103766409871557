import { Injectable } from '@angular/core';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseActions, SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { SocieteConfigAnalyseSyntheseApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { SocieteConfigAnalyseSyntheseSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  GeneratedSocieteConfigAnalyseSyntheseEffects,
  getDefaultDeleteSocieteConfigAnalyseSyntheseActions
} from './societe-config-analyse-synthese-generated.effects';

@Injectable()
export class SocieteConfigAnalyseSyntheseEffects extends GeneratedSocieteConfigAnalyseSyntheseEffects {
  constructor(
    actions$: Actions,
    societeConfigAnalyseSyntheseApiService: SocieteConfigAnalyseSyntheseApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeConfigAnalyseSyntheseApiService, store$);
  }

  handleManySocieteConfigAnalyseSynthese$ = createEffect(() => {
    const selectSocieteConfigAnalyseSyntheseState$ = this.store$.select(
      SocieteConfigAnalyseSyntheseSelectors.selectSocieteConfigAnalyseSyntheseState
    );
    return this.actions$.pipe(
      ofType(SocieteConfigAnalyseSyntheseActions.handleManySocieteConfigAnalyseSynthese),
      withLatestFrom(selectSocieteConfigAnalyseSyntheseState$),
      concatMap(([{ configs }, state]) => {
        return this.societeConfigAnalyseSyntheseApiService.handleManySocieteConfigAnalyseSynthese(configs).pipe(
          switchMap((societeConfigAnalyseSyntheseReturned: SocieteConfigAnalyseSynthese[]) => {
            const actions = (
              [
                SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert({
                  societeConfigAnalyseSyntheses: societeConfigAnalyseSyntheseReturned
                })
              ] as Action[]
            ).concat(
              configs?.deleted
                ?.map(id =>
                  getDefaultDeleteSocieteConfigAnalyseSyntheseActions(
                    state.entities[id] as SocieteConfigAnalyseSyntheseEntityState
                  )
                )
                .flat() || []
            );
            return actions;
          }),
          catchError(error =>
            of(SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure({ error }))
          )
        );
      })
    );
  });
}
