import { Injectable } from '@angular/core';
import { GridError, SocieteEspaceDataRow } from '@get/api-interfaces';
import { GridErrorSocieteEspaceActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorSocieteEspaceSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorSocieteEspaceService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorSocieteEspace: Partial<GridError>): number | undefined {
    if (!gridErrorSocieteEspace.error.idSocieteEspace) {
      gridErrorSocieteEspace.error.idSocieteEspace = --this.tempId;
    }
    gridErrorSocieteEspace.idGridError = gridErrorSocieteEspace.error.idSocieteEspace;
    this.store$.dispatch(
      GridErrorSocieteEspaceActions.addOneGridErrorSocieteEspace({
        gridErrorSocieteEspace: gridErrorSocieteEspace as GridError
      })
    );
    return gridErrorSocieteEspace.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(GridErrorSocieteEspaceActions.deleteOneGridErrorSocieteEspaceSuccess({ idGridError }));
  }

  public selectSocieteEspaceAndGridErrors(schema: SelectSchema = {}): Observable<SocieteEspaceDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSocieteEspaceSelectors.selectSocieteEspaceAndGridErrors(schema)))
      .pipe(map(societeEspaceDataRow => societeEspaceDataRow));
  }

  public selectSocieteEspaceFamilleAndGridErrors(schema: SelectSchema = {}): Observable<SocieteEspaceDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSocieteEspaceSelectors.selectSocieteEspaceFamilleAndGridErrors(schema)))
      .pipe(map(societeEspaceDataRow => societeEspaceDataRow));
  }
}
