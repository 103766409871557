import { SocieteComposantToHandle } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const SocieteComposantActions = createActionGroup({
  source: 'Societe Composant',
  events: {
    'Get Many Societe Composant Descriptions': props<{ params: any }>(),
    'Handle Many Societe Composants': props<SocieteComposantToHandle>(),
    'Delete One Societe Composant With Cascade': props<{ idSocieteComposant: number }>()
  }
});
