import { SocieteEspaceState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeEspaceReducersGeneratedFunctions } from './societe-espace-generated.reducer';

const societeEspaceReducersFunctions = [...societeEspaceReducersGeneratedFunctions];

const societeEspaceReducer = createReducer(SocieteEspaceState.initialState, ...societeEspaceReducersFunctions);

export function reducer(state: SocieteEspaceState.IState | undefined, action: Action) {
  return societeEspaceReducer(state, action);
}
