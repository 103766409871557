import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedUserSocieteProfilApiService } from './user-societe-profil-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserSocieteProfilApiService extends GeneratedUserSocieteProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
