import { Injectable } from '@angular/core';
import { AppState } from '@get/store/configs/reducers';
import { Action, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(private store$: Store<AppState>) {}

  public dispatchAction<T>(action: (param: T) => Action, params: T): void {
    this.store$.dispatch(action(params));
  }
}
