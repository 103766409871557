import { GridErrorSocieteEspaceFamilleActions } from '@get/store/actions';
import { GridErrorSocieteEspaceFamilleState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorSocieteEspaceFamilleState.IState, readonly ActionCreator[]>[] =
  [];

export function setLoadingsState(
  state: GridErrorSocieteEspaceFamilleState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorSocieteEspaceFamilleState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorSocieteEspaceFamilleActions.addOneGridErrorSocieteEspaceFamille,
    (state: GridErrorSocieteEspaceFamilleState.IState, { gridErrorSocieteEspaceFamille }) =>
      GridErrorSocieteEspaceFamilleState.adapter.upsertMany(
        [gridErrorSocieteEspaceFamille],
        setLoadingsState(state, false)
      )
  ),
  on(
    GridErrorSocieteEspaceFamilleActions.deleteOneGridErrorSocieteEspaceFamilleSuccess,
    (state: GridErrorSocieteEspaceFamilleState.IState, { idGridError }) =>
      GridErrorSocieteEspaceFamilleState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorSocieteEspaceFamilleState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorSocieteEspaceFamilleState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
