import { GridErrorEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';
import { createEntityAdapter } from '@ngrx/entity';

export type IState = CustomEntityState<GridErrorEntityState>;

export const adapter = createEntityAdapter<GridErrorEntityState>({
  selectId: o => o.idGridError
});
export const initialState: IState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const gridErrorFeatureKey = 'gridErrorSocieteEspaceFamille';
