import { SocieteProfilDroitUpdate } from '@get/api-interfaces';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const SocieteProfilDroitActions = createActionGroup({
  source: 'Societe Profil Droit',
  events: {
    'Upsert Many Societe Profil Droit With Societe Profil': props<{
      societeProfilDroitUpdate: SocieteProfilDroitUpdate;
      ids?: SocieteProfilDroitRelationsIds;
    }>()
  }
});
