import { Injectable } from '@angular/core';
import { User } from '@get/api-interfaces';
import { UserApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { UserRelationsIds } from '@get/store/ids-interfaces';
import { UserSelectors } from '@get/store/selectors';
import { DroitModel, SocieteProfilModel, UserPatrimoineModel } from '@get/store/selectors-model';
import { catchApiActions, SelectSchema } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OrganisationParamsUtils } from '@utils';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { GeneratedUserService } from './user-generated.service';
import { UserActions, UserGeneratedActions } from '@get/store/actions';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GeneratedUserService {
  public currentUser: User | null = null;

  private isUserHasUpdate$ = new ReplaySubject<void>(1);

  constructor(store$: Store<AppState>, actions$: Actions, private userApiService: UserApiService) {
    super(store$, actions$);
  }

  public getManyForCampaign(params: any = {}, getResult?: boolean): void | Observable<User[]> {
    this.store$.dispatch(UserActions.getManyForCampaign({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserGeneratedActions.normalizeManyUsersAfterUpsert,
        UserGeneratedActions.usersFailure
      );
    }
  }

  public userHasUpdate(): Observable<void> {
    return this.isUserHasUpdate$.asObservable();
  }

  public updateUserPassword(user: User): Observable<Object> {
    return this.userApiService.updateUserPassword(user);
  }

  public override selectAllUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(UserSelectors.selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users.filter(user => !user.disable)));
      })
    );
  }

  public selectAllUsersWithDisable(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(UserSelectors.selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectAllUsersByCurrentOrganisation(schema: SelectSchema = {}): Observable<User[]> {
    return this.selectAllUsers(schema).pipe(
      map((users: User[]) =>
        users.filter((user: User) => user.idOrganisation === OrganisationParamsUtils.getCurrentOrganisationId())
      )
    );
  }

  public updateCurrentUser(updatedUser?: User): Observable<User> {
    if (updatedUser) {
      this.currentUser = updatedUser;
      this.isUserHasUpdate$.next();
    }
    if (!this.currentUser) {
      return EMPTY;
    }
    return this.selectOneUser(this.currentUser?.idUser, {
      include: [UserPatrimoineModel, { model: SocieteProfilModel, include: [DroitModel] }]
    }).pipe(
      tap(user => {
        this.currentUser = user;
        this.isUserHasUpdate$.next();
      })
    );
  }

  public override upsertOneUser(
    user: Partial<User>,
    ids: UserRelationsIds = {},
    getResult?: boolean
  ): void | Observable<User> {
    const ret = super.upsertOneUser(user, ids, getResult);
    if (user.idUser && this?.currentUser?.idUser === user.idUser) {
      // TODO: Check car ne semble rien faire, mais le subscribe dans le app resolver peut suffire
      this.updateCurrentUser();
    }
    return ret;
  }
}
