import { SocieteProfilDroitState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeProfilDroitReducersGeneratedFunctions } from './societe-profil-droit-generated.reducer';

const societeProfilDroitReducersFunctions = [...societeProfilDroitReducersGeneratedFunctions];

const societeProfilDroitReducer = createReducer(
  SocieteProfilDroitState.initialState,
  ...societeProfilDroitReducersFunctions
);

export function reducer(state: SocieteProfilDroitState.IState | undefined, action: Action) {
  return societeProfilDroitReducer(state, action);
}
