import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, Patrimoine } from '@get/api-interfaces';

export function getDynamicBreadcrumbForConsult(route: ActivatedRoute, patrimoine: Patrimoine): Breadcrumb[] {
  const breadcrumbs: Breadcrumb[] = [];
  let child = route.snapshot.root.firstChild;
  let url = '';
  while (child) {
    const routeURL = child.url.map(segment => segment.path).join('/');

    if (routeURL !== '') {
      if (child.data?.['breadcrumb'] !== '__dynamic__') {
        url += `/${routeURL}`;
      }
    }

    child = child.firstChild;
  }
  const ancetres = patrimoine?.ancetres?.sort((a, b) => b.niveau - a.niveau);
  for (let i = 0; i < ancetres?.length; i++) {
    const ancetre = ancetres[i];
    const label = ancetre?.ancetrePatrimoine?.societePatrimoineHierarchie?.libelle;
    const idPatrimoine = ancetre.idAncetrePatrimoine;
    if (idPatrimoine) {
      breadcrumbs.push({ active: false, label, url: `${url}/${idPatrimoine}` });
    }
  }
  breadcrumbs.push({
    active: true,
    label: patrimoine?.societePatrimoineHierarchie?.libelle,
    url: `${url}/${patrimoine.idPatrimoine}`
  });
  return breadcrumbs;
}

export function getDynamicBreadcrumbForConsultComponent(
  route: ActivatedRoute,
  patrimoine: Patrimoine,
  componentName: string
): Breadcrumb[] {
  const breadcrumbs: Breadcrumb[] = [];
  let child = route.snapshot.root.firstChild;
  let url = '';
  while (child) {
    const routeURL = child.url.map(segment => segment.path).join('/');

    if (routeURL !== '') {
      if (child.data?.['breadcrumb'] !== '__dynamic__') {
        url += `/${routeURL}`;
      }
    }

    child = child.firstChild;
  }
  const ancetres = patrimoine?.ancetres?.sort((a, b) => b.niveau - a.niveau);
  for (let i = 0; i < ancetres?.length; i++) {
    const ancetre = ancetres[i];
    const label = ancetre?.ancetrePatrimoine?.societePatrimoineHierarchie?.libelle;
    const idPatrimoine = ancetre.idAncetrePatrimoine;
    if (idPatrimoine) {
      breadcrumbs.push({ active: false, label, url: `${url}/${idPatrimoine}` });
    }
  }
  breadcrumbs.push({
    active: false,
    label: patrimoine?.societePatrimoineHierarchie?.libelle,
    url: `${url}/${patrimoine.idPatrimoine}`
  });
  const componentType = route.snapshot.params['componentType'];
  const id = route.snapshot.params['id'];
  breadcrumbs.push({
    active: true,
    label: componentName,
    url: `${url}/${patrimoine.idPatrimoine}/${componentType}/${id}`
  });
  return breadcrumbs;
}
