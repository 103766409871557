import { Injectable } from '@angular/core';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { OrganisationActions, OrganisationGeneratedActions } from '@get/store/actions';
import { OrganisationApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedOrganisationEffects, getDefaultDeleteOrganisationActions } from './organisation-generated.effects';
import { OrganisationSelectors } from '@get/store/selectors';

@Injectable()
export class OrganisationEffects extends GeneratedOrganisationEffects {
  constructor(actions$: Actions, organisationApiService: OrganisationApiService, store$: Store<AppState>) {
    super(actions$, organisationApiService, store$);
  }

  getUserOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganisationActions.getUserOrganisation),
      switchMap(idUser =>
        this.organisationApiService.getUserOrganisation(idUser).pipe(
          map((organisation: Organisation) => {
            return OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({
              organisations: [organisation]
            });
          }),
          catchError(error => of(OrganisationGeneratedActions.organisationsFailure({ error })))
        )
      )
    );
  });

  upsertOrganisationOnAppInit$ = createEffect(() => {
    const selectOrganisationState$ = this.store$.select(OrganisationSelectors.selectOrganisationState);
    return this.actions$.pipe(
      ofType(OrganisationActions.upsertOrganisationOnAppInit),
      withLatestFrom(selectOrganisationState$),
      mergeMap(([{ organisation }, state]) => {
        const actions: Action[] = [
          OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({
            organisations: [organisation]
          })
        ];
        const orgas = Object.keys(state.entities);
        const orgasToRemove = orgas.filter(idOrga => organisation.idOrganisation !== +idOrga);
        const removeActions = orgasToRemove
          .map(idOrga => getDefaultDeleteOrganisationActions(state.entities[idOrga] as OrganisationEntityState))
          .flat();
        return actions.concat(removeActions);
      })
    );
  });
}
