import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@get/store/configs/reducers';
import { GeneratedNewsletterService } from './newsletter-generated.service';
import { Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Newsletter, UserNewsletter } from '@get/api-interfaces';
import { NewsletterActions, NewsletterGeneratedActions, UserNewsletterGeneratedActions } from '@get/store/actions';
import { catchApiActions } from '@get/utils';
@Injectable({
  providedIn: 'root'
})
export class NewsletterService extends GeneratedNewsletterService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public getManyNewslettersByOrganisation(params: any = {}, getResult?: boolean): void | Observable<Newsletter[]> {
    this.store$.dispatch(NewsletterActions.getManyNewslettersByOrganisation({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure
      );
    }
  }

  public getNewslettersUnread(params: any = {}, getResult?: boolean): void | Observable<Newsletter[]> {
    this.store$.dispatch(NewsletterActions.getNewslettersUnread({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        NewsletterGeneratedActions.normalizeManyNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure
      );
    }
  }

  public markAsRead(idsNewsletter: number[], getResult?: boolean): void | Observable<UserNewsletter[]> {
    this.store$.dispatch(NewsletterActions.markAsRead({ idsNewsletter }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserNewsletterGeneratedActions.normalizeManyUserNewslettersAfterUpsert,
        NewsletterGeneratedActions.newslettersFailure,
        true
      );
    }
  }
}
