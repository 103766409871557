import { CollectPatrimoine } from '@get/api-interfaces';

export function findFirstSelectablePatrimoine(patrimoines: CollectPatrimoine[]): CollectPatrimoine | null {
  for (let i = 0; i < patrimoines.length; i++) {
    const patrimoine = patrimoines[i];
    if (patrimoine.selectable) {
      return patrimoine;
    }
    const firstSelectableDescendant = findFirstSelectablePatrimoine(patrimoine.children || []);
    if (firstSelectableDescendant) {
      return firstSelectableDescendant;
    }
  }
  return null;
}

export function findPatrimoineInTree(patrimoines: CollectPatrimoine[], idPatrimoine: number): CollectPatrimoine | null {
  for (let i = 0; i < patrimoines.length; i++) {
    const patrimoine = patrimoines[i];
    if (patrimoine.idPatrimoine === idPatrimoine) {
      return patrimoine;
    }
    const matchingPatrimoine = findPatrimoineInTree(patrimoine.children || [], idPatrimoine);
    if (matchingPatrimoine) {
      return matchingPatrimoine;
    }
  }
  return null;
}
