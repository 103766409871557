import { HeaderTitle } from '@get/interfaces';
import { joinStringArrayWithSeparator } from '@utils';

export function formatShareMailBody(title: string, url: string): string {
  return encodeURI(`Fiche d'information Get and Share

Bonjour,

Veuillez trouver le lien de partage vous permettant d'accéder aux informations nécessaires de la résidence ${title}

${url ?? ''}

`);
}

export function formatHeaderTitle(header: HeaderTitle): string {
  return joinStringArrayWithSeparator(header?.title?.content ?? [], header?.title.separator ?? ' - ');
}
