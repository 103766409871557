import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampagnePatrimoineApiService } from '@get/store/api-services';
import { GeneratedCampagnePatrimoineEffects } from './campagne-patrimoine-generated.effects';

@Injectable()
export class CampagnePatrimoineEffects extends GeneratedCampagnePatrimoineEffects {
  constructor(actions$: Actions, campagnePatrimoineApiService: CampagnePatrimoineApiService, store$: Store<AppState>) {
    super(actions$, campagnePatrimoineApiService, store$);
  }
}
