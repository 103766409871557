import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedValeurPatrimoineApiService } from './valeur-patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurPatrimoineApiService extends GeneratedValeurPatrimoineApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
