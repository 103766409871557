import { Injectable } from '@angular/core';
import { AppState } from '@get/store/configs/reducers';
import { SocieteApiService } from '@get/store/api-services';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GeneratedSocieteEffects } from './societe-generated.effects';

@Injectable()
export class SocieteEffects extends GeneratedSocieteEffects {
  constructor(actions$: Actions, societeApiService: SocieteApiService, store$: Store<AppState>) {
    super(actions$, societeApiService, store$);
  }
}
