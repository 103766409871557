import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedSocieteProfilDroitApiService } from './societe-profil-droit-api-generated.service';
import { Observable } from 'rxjs';
import { SocieteProfil, SocieteProfilDroitUpdate } from '@get/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class SocieteProfilDroitApiService extends GeneratedSocieteProfilDroitApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public upsertManySocieteProfilDroitWithSocieteProfil(
    societeProfilDroitUpdate: SocieteProfilDroitUpdate
  ): Observable<SocieteProfil> {
    return this.repo.update('societe-profil-droit/societe-profil', societeProfilDroitUpdate);
  }
}
