import { GridErrorSocieteComposantActions } from '@get/store/actions';
import { GridErrorSocieteComposantState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorSocieteComposantState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorSocieteComposantState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorSocieteComposantState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorSocieteComposantActions.addOneGridErrorSocieteComposant,
    (state: GridErrorSocieteComposantState.IState, { gridErrorSocieteComposant }) =>
      GridErrorSocieteComposantState.adapter.upsertMany([gridErrorSocieteComposant], setLoadingsState(state, false))
  ),
  on(
    GridErrorSocieteComposantActions.deleteOneGridErrorSocieteComposantSuccess,
    (state: GridErrorSocieteComposantState.IState, { idGridError }) =>
      GridErrorSocieteComposantState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorSocieteComposantState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorSocieteComposantState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
