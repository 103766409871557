import { Injectable } from '@angular/core';
import {
  SocieteComposant,
  SocieteEspaceEntityState,
  SocieteEspaceFamille,
  SocieteEspaceFamilleEntityState
} from '@get/api-interfaces';
import {
  SocieteComposantGeneratedActions,
  SocieteEspaceFamilleActions,
  SocieteEspaceFamilleGeneratedActions
} from '@get/store/actions';
import { SocieteEspaceFamilleApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { AppState } from '@get/store/configs/reducers';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';
import { SocieteEspaceFamilleSelectors, SocieteEspaceSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  GeneratedSocieteEspaceFamilleEffects,
  getDefaultAddSocieteEspaceFamilleActions,
  getDefaultDeleteSocieteEspaceFamilleActions
} from './societe-espace-famille-generated.effects';
import { getDefaultDeleteSocieteEspaceActions } from './societe-espace-generated.effects';

@Injectable()
export class SocieteEspaceFamilleEffects extends GeneratedSocieteEspaceFamilleEffects {
  constructor(
    actions$: Actions,
    societeEspaceFamilleApiService: SocieteEspaceFamilleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeEspaceFamilleApiService, store$);
  }

  upsertOneSocieteEspaceFamilleWithSocieteComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleActions.upsertOneSocieteEspaceFamilleWithSocieteComposant),
      concatMap(
        ({
          societeEspaceFamille,
          ids
        }: {
          societeEspaceFamille: Partial<SocieteEspaceFamille>;
          ids?: SocieteEspaceFamilleRelationsIds;
        }) => {
          if (societeEspaceFamille.idSocieteEspaceFamille) {
            return this.societeEspaceFamilleApiService
              .updateSocieteEspaceFamilleWithSocieteComposant(societeEspaceFamille)
              .pipe(
                mergeMap(
                  ({
                    societeEspaceFamille,
                    societeComposantsToReturn
                  }: {
                    societeEspaceFamille: SocieteEspaceFamille;
                    societeComposantsToReturn: SocieteComposant[];
                  }) => {
                    return [
                      SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert({
                        societeEspaceFamilles: [societeEspaceFamille]
                      }),
                      SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({
                        societeComposants: societeComposantsToReturn
                      })
                    ];
                  }
                ),
                catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
              );
          } else {
            return this.societeEspaceFamilleApiService.addSocieteEspaceFamille(societeEspaceFamille).pipe(
              mergeMap((societeEspaceFamilleReturned: SocieteEspaceFamille) =>
                getDefaultAddSocieteEspaceFamilleActions(societeEspaceFamilleReturned, ids)
              ),
              catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSocieteEspaceFamilleWithSocieteEspace$ = createEffect(() => {
    const selectSocieteEspaceFamilleState$ = this.store$.select(
      SocieteEspaceFamilleSelectors.selectSocieteEspaceFamilleState
    );
    const selectSocieteEspaceState$ = this.store$.select(SocieteEspaceSelectors.selectSocieteEspaceState);
    return this.actions$.pipe(
      ofType(SocieteEspaceFamilleActions.deleteOneSocieteEspaceFamilleWithSocieteEspace),
      withLatestFrom(selectSocieteEspaceFamilleState$),
      withLatestFrom(selectSocieteEspaceState$),
      concatMap(([[{ idSocieteEspaceFamille }, state], stateSocieteEspace]) => {
        return this.societeEspaceFamilleApiService
          .deleteOneSocieteEspaceFamilleWithSocieteEspace(idSocieteEspaceFamille)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteSocieteEspaceFamilleActions(
                  state.entities[idSocieteEspaceFamille] as SocieteEspaceFamilleEntityState
                ),
                SocieteEspaceFamilleActions.deleteOneSocieteEspaceFamilleWithSocieteEspace.type
              ),
              getMultiAction(
                Object.values(stateSocieteEspace.entities)
                  .filter(societeEspace => societeEspace?.idSocieteEspaceFamille === idSocieteEspaceFamille)
                  .map(societeEspace => getDefaultDeleteSocieteEspaceActions(societeEspace as SocieteEspaceEntityState))
                  .flat(),
                SocieteEspaceFamilleActions.deleteOneSocieteEspaceFamilleWithSocieteEspace.type
              )
            ]),
            catchError(error => of(SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure({ error })))
          );
      })
    );
  });
}
