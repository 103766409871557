import { Injectable } from '@angular/core';
import { StoreActionType } from '@enums';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { UserPatrimoineActions, UserPatrimoineGeneratedActions } from '@get/store/actions';
import { UserPatrimoineApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { getActionsToNormalizeUserPatrimoine } from '@get/store/configs/normalization';
import { AppState } from '@get/store/configs/reducers';
import { UserPatrimoineSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  GeneratedUserPatrimoineEffects,
  getDefaultAddUserPatrimoineActions,
  getDefaultDeleteUserPatrimoineActions
} from './user-patrimoine-generated.effects';

@Injectable()
export class UserPatrimoineEffects extends GeneratedUserPatrimoineEffects {
  constructor(actions$: Actions, userPatrimoineApiService: UserPatrimoineApiService, store$: Store<AppState>) {
    super(actions$, userPatrimoineApiService, store$);
  }

  getManyUserPatrimoinesForUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.getManyUserPatrimoinesForUser),
      switchMap(({ params }) =>
        this.userPatrimoineApiService.getManyUserPatrimoinesForUser(params).pipe(
          map((userPatrimoines: UserPatrimoine[]) =>
            UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({ userPatrimoines })
          ),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        )
      )
    );
  });

  getManyUserPatrimoinesForPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.getManyUserPatrimoinesForPatrimoine),
      switchMap(({ params }) =>
        this.userPatrimoineApiService.getManyUserPatrimoinesForPatrimoine(params).pipe(
          map((userPatrimoines: UserPatrimoine[]) =>
            UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({ userPatrimoines })
          ),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertManyUserPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.handleManyUserPatrimoines),
      concatMap(({ userPatrimoines }) => {
        return this.userPatrimoineApiService.handleManyUserPatrimoines(userPatrimoines).pipe(
          map((userPatrimoinesReturned: UserPatrimoine[]) =>
            UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({
              userPatrimoines: userPatrimoinesReturned
            })
          ),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        );
      })
    );
  });

  handleManyOwnUserPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.handleManyOwnUserPatrimoines),
      concatMap(({ userPatrimoines }) => {
        return this.userPatrimoineApiService.handleManyOwnUserPatrimoines(userPatrimoines).pipe(
          map((userPatrimoinesReturned: UserPatrimoine[]) =>
            UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert({
              userPatrimoines: userPatrimoinesReturned
            })
          ),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        );
      })
    );
  });

  createManyUserPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.createManyUserPatrimoines),
      concatMap(({ userPatrimoines, origin }) => {
        return this.userPatrimoineApiService.createManyUserPatrimoines(userPatrimoines).pipe(
          mergeMap((userPatrimoinesReturned: UserPatrimoine[]) => {
            const actions: Action[] = [];
            for (let i = 0; i < userPatrimoinesReturned.length; i++) {
              const up = userPatrimoinesReturned[i];
              // Laisser les 2 actions cohabiter car la seconde ne trigger pas la normalization correctement
              // (malgré le fait que le tableau contienne l'action)
              actions.push(...getActionsToNormalizeUserPatrimoine([up], StoreActionType.upsert));
              actions.push(
                ...getDefaultAddUserPatrimoineActions(
                  up,
                  origin === 'user' ? { user: up.idUser } : { patrimoine: up.idPatrimoine }
                )
              );
            }
            if (actions.length > 1) {
              return [getMultiAction(actions, UserPatrimoineActions.createManyUserPatrimoines.type)];
            }
            return actions;
          }),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        );
      })
    );
  });

  deleteManyUserPatrimoine$ = createEffect(() => {
    const selectUserPatrimoineState$ = this.store$.select(UserPatrimoineSelectors.selectUserPatrimoineState);
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.deleteManyUserPatrimoines),
      withLatestFrom(selectUserPatrimoineState$),
      concatMap(([{ params }, state]) =>
        this.userPatrimoineApiService.deleteManyUserPatrimoine(params).pipe(
          mergeMap(success => {
            if (success.affectedRows === params.idsUserPatrimoine.length) {
              return [
                getMultiAction(
                  params.idsUserPatrimoine
                    .map(idUserPatrimoine =>
                      getDefaultDeleteUserPatrimoineActions(
                        state.entities[idUserPatrimoine] as UserPatrimoineEntityState
                      )
                    )
                    .flat(),
                  UserPatrimoineActions.deleteManyUserPatrimoines.type
                )
              ];
            }
            return [];
          }),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        )
      )
    );
  });

  deleteManyUserPatrimoinesByIds$ = createEffect(() => {
    const selectUserPatrimoineState$ = this.store$.select(UserPatrimoineSelectors.selectUserPatrimoineState);
    return this.actions$.pipe(
      ofType(UserPatrimoineActions.deleteManyUserPatrimoinesByIds),
      withLatestFrom(selectUserPatrimoineState$),
      concatMap(([{ idsUserPatrimoine }, state]) =>
        this.userPatrimoineApiService.deleteManyUserPatrimoinesByIds(idsUserPatrimoine).pipe(
          mergeMap(success => {
            if (success.affectedRows === idsUserPatrimoine.length) {
              return [
                getMultiAction(
                  idsUserPatrimoine
                    .map(idUserPatrimoine =>
                      getDefaultDeleteUserPatrimoineActions(
                        state.entities[idUserPatrimoine] as UserPatrimoineEntityState
                      )
                    )
                    .flat(),
                  UserPatrimoineActions.deleteManyUserPatrimoinesByIds.type
                )
              ];
            }
            return [];
          }),
          catchError(error => of(UserPatrimoineGeneratedActions.userPatrimoinesFailure({ error })))
        )
      )
    );
  });
}
