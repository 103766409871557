import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';
import { hydrateAll, patrimoineRelations, selectPatrimoinesEntities } from './patrimoine-generated.selectors';

export * from './patrimoine-generated.selectors';

const patrimoinesInObject = (patrimoines: Dictionary<PatrimoineEntityState>) => ({ patrimoines });
const selectPatrimoinesEntitiesDictionary = createSelector(selectPatrimoinesEntities, patrimoinesInObject);

const selectAllPatrimoinesHighestAncestorsSelectors: Dictionary<Selector> = {};
function selectAllPatrimoinesWithSchema(schema: SelectSchema = {}): Selector {
  return findOrCreateSelector<Patrimoine>(
    schema,
    selectAllPatrimoinesHighestAncestorsSelectors,
    selectPatrimoinesEntitiesDictionary,
    getRelationSelectors,
    patrimoineRelations,
    hydrateAll,
    'patrimoine'
  );
}

export function selectAllPatrimoinesHighestAncestors(schema: SelectSchema = {}): Selector {
  return createSelector(selectAllPatrimoinesWithSchema(schema), ({ patrimoines }: { patrimoines: Patrimoine[] }) => ({
    patrimoines: patrimoines?.filter(patrimoine => !patrimoine?.societePatrimoineHierarchie?.hierarchieAncetres?.length)
  }));
}

const filterPatrimoinesDescendants = (
  patrimoines: Dictionary<PatrimoineEntityState>,
  idPatrimoine: number,
  idSocietePatrimoineHierarchie: number
) => {
  return Object.keys(patrimoines).reduce((acc, patrimoineObject) => {
    const patrimoine = patrimoines[patrimoineObject];
    if (
      patrimoine?.idSocietePatrimoineHierarchie === idSocietePatrimoineHierarchie ||
      patrimoine?.idPatrimoine === idPatrimoine
    ) {
      return Object.assign(acc, { [patrimoineObject]: patrimoine });
    }
    return acc;
  }, {});
};

const patrimoineDescendantsInObjectFiltered = (
  patrimoine: Dictionary<PatrimoineEntityState>,
  idPatrimoine: number,
  idSocietePatrimoineHierarchie: number
) => ({
  patrimoines: filterPatrimoinesDescendants(patrimoine, idPatrimoine, idSocietePatrimoineHierarchie)
});

const selectPatrimoineDescendantsEntitiesDictionary = (idPatrimoine: number, idSocietePatrimoineHierarchie: number) =>
  createSelector(selectPatrimoinesEntities, patrimoines =>
    patrimoineDescendantsInObjectFiltered(patrimoines, idPatrimoine, idSocietePatrimoineHierarchie)
  );

const selectPatrimoineDescendantsDictionaryWithoutChild = (
  idPatrimoine: number,
  idSocietePatrimoineHierarchie: number
) =>
  createSelector(selectPatrimoinesEntities, patrimoines => {
    return patrimoineDescendantsInObjectFiltered(patrimoines, idPatrimoine, idSocietePatrimoineHierarchie);
  });

// TODO: N'est plus utilisée mais sert d'exemple pour créer des selectors custom (supprimer dès qu'un exemple similaire existe)
export function selectOnePatrimoineDescendants(
  schema: SelectSchema = {},
  idPatrimoine: number,
  idSocietePatrimoineHierarchie: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectPatrimoineDescendantsEntitiesDictionary(idPatrimoine, idSocietePatrimoineHierarchie)
    ];
    selectors.push(...getRelationSelectors(schema, patrimoineRelations, 'patrimoine'));
    return (createSelector as any)(...selectors, hydrateAll);
  } else {
    return selectPatrimoineDescendantsDictionaryWithoutChild(idPatrimoine, idSocietePatrimoineHierarchie);
  }
}
