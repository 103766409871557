import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurFichierApiService } from '@get/store/api-services';
import { GeneratedValeurFichierEffects } from './valeur-fichier-generated.effects';

@Injectable()
export class ValeurFichierEffects extends GeneratedValeurFichierEffects {
  constructor(actions$: Actions, valeurFichierApiService: ValeurFichierApiService, store$: Store<AppState>) {
    super(actions$, valeurFichierApiService, store$);
  }
}
