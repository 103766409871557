import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@get/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RepositoryService {
  constructor(private http: HttpClient) {}

  public getData<T>(route: string, params?: any): Observable<T> {
    return this.http
      .get<{ data: T }>(this.createCompleteRoute(route, environment.apiUrl), {
        params: this.setParams(params ?? {})
      })
      .pipe(map((res: { data: T }) => res.data));
  }

  public create<T>(route: string, body: any, params?: any): Observable<T> {
    return this.http
      .post<{ data: T }>(this.createCompleteRoute(route, environment.apiUrl), body, this.generateHeaders(params))
      .pipe(map((res: { data: T }) => res.data));
  }

  public update<T>(route: string, body: any, params?: any): Observable<T> {
    return this.http
      .put<{ data: T }>(this.createCompleteRoute(route, environment.apiUrl), body, this.generateHeaders(params))
      .pipe(map((res: { data: T }) => res.data));
  }

  public postForFile(route: string, body: any) {
    return this.http.post(route, body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  public delete<T = number>(route: string, body: any = undefined): Observable<T> {
    return this.http
      .delete<{ data: T }>(this.createCompleteRoute(route, environment.apiUrl), { body })
      .pipe(map((res: { data: T }) => res.data));
  }

  public deleteMany(route: string): Observable<number[]> {
    return this.http
      .delete<{ data: number[] }>(this.createCompleteRoute(route, environment.apiUrl))
      .pipe(map((res: { data: number[] }) => res.data));
  }

  private createCompleteRoute(route: string, envAddress: string): string {
    return `${envAddress}/${route}`;
  }

  private generateHeaders(params?: any): { headers: HttpHeaders; params: HttpParams } {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: this.setParams(params ?? {})
    };
  }

  private setParams(object: any): HttpParams {
    let params = new HttpParams();

    const formatedObject = Object.keys(object).every(key => key === 'params') ? object.params : object;

    for (const key in formatedObject) {
      if (formatedObject[key] !== null && formatedObject[key] !== undefined) {
        if (Array.isArray(formatedObject[key])) {
          formatedObject[key].forEach((element: string | number | boolean) => {
            params = params.append(`${key}[]`, element);
          });
        } else {
          params = params.append(key, formatedObject[key]);
        }
      }
    }
    return params;
  }
}
