import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@get/services/storage';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@get/services/auth';
import { LoginDataUser } from '@get/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class IsUserLogged {
  constructor(private router: Router, private authService: AuthService, private storageService: StorageService) {}

  private renewTryNb = 0;

  public canActivate(): Observable<boolean> | boolean {
    if (!this.authService.isLogged) {
      this.redirectToLogin();
      return false;
    }
    return this.authService.renew().pipe(
      map((userData: LoginDataUser): boolean => {
        this.storageService.setStorage('login', { token: userData.data.jwtToken });
        this.authService.getLoginStorage();
        return true;
      }),
      catchError((err, caught) => {
        if (err.status === 504 && err.statusText === 'Gateway Timeout') {
          this.authService.getLoginStorage();
          return of(true);
        }
        return timer(500).pipe(
          mergeMap(() => {
            this.renewTryNb++;
            if (this.renewTryNb > 3) {
              this.redirectToLogin();
            }
            return caught;
          })
        );
      })
    );
  }

  private redirectToLogin(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
