import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedComposantGroupeApiService } from './composant-groupe-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ComposantGroupeApiService extends GeneratedComposantGroupeApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
