import { SocieteComposantRattachementState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeComposantRattachementReducersGeneratedFunctions } from './societe-composant-rattachement-generated.reducer';

const societeComposantRattachementReducersFunctions = [...societeComposantRattachementReducersGeneratedFunctions];

const societeComposantRattachementReducer = createReducer(
  SocieteComposantRattachementState.initialState,
  ...societeComposantRattachementReducersFunctions
);

export function reducer(state: SocieteComposantRattachementState.IState | undefined, action: Action) {
  return societeComposantRattachementReducer(state, action);
}
