import {
  DeleteManyUserPatrimoineParams,
  UserPatrimoine,
  UserPatrimoineForPatrimoineParams,
  UserPatrimoineForUserParams
} from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const UserPatrimoineActions = createActionGroup({
  source: 'User Patrimoine',
  events: {
    'Get Many User Patrimoines For User': props<{ params: UserPatrimoineForUserParams }>(),
    'Get Many User Patrimoines For Patrimoine': props<{ params: UserPatrimoineForPatrimoineParams }>(),
    'Handle Many User Patrimoines': props<{ userPatrimoines: UserPatrimoine[] }>(),
    'Handle Many Own User Patrimoines': props<{ userPatrimoines: UserPatrimoine[] }>(),
    'Create Many User Patrimoines': props<{ userPatrimoines: UserPatrimoine[]; origin: 'user' | 'patrimoine' }>(),
    'Delete Many User Patrimoines': props<{ params: DeleteManyUserPatrimoineParams }>(),
    'Delete Many User Patrimoines By Ids': props<{ idsUserPatrimoine: number[] }>()
  }
});
