import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedCampagnePatrimoineApiService } from './campagne-patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CampagnePatrimoineApiService extends GeneratedCampagnePatrimoineApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
