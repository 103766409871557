import { Injectable } from '@angular/core';
import { Newsletter, UserNewsletter } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedNewsletterApiService } from './newsletter-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterApiService extends GeneratedNewsletterApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getNewslettersByOrganisation(params?: any): Observable<Newsletter[]> {
    return this.repo.getData<Newsletter[]>('newsletter/by-organisation/' + params, params);
  }

  public getNewslettersUnread(params?: any): Observable<Newsletter[]> {
    return this.repo.getData<Newsletter[]>('newsletter/unread-newsletters', params);
  }

  public markAsRead(idsNewsletter: number[]): Observable<UserNewsletter[]> {
    return this.repo.update('newsletter/mark-as-read', idsNewsletter);
  }
}
