import { GridErrorSocieteEspaceActions } from '@get/store/actions';
import { GridErrorSocieteEspaceState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorSocieteEspaceState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorSocieteEspaceState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorSocieteEspaceState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorSocieteEspaceActions.addOneGridErrorSocieteEspace,
    (state: GridErrorSocieteEspaceState.IState, { gridErrorSocieteEspace }) =>
      GridErrorSocieteEspaceState.adapter.upsertMany([gridErrorSocieteEspace], setLoadingsState(state, false))
  ),
  on(
    GridErrorSocieteEspaceActions.deleteOneGridErrorSocieteEspaceSuccess,
    (state: GridErrorSocieteEspaceState.IState, { idGridError }) =>
      GridErrorSocieteEspaceState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorSocieteEspaceState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorSocieteEspaceState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
