import { ValeurState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurReducersGeneratedFunctions } from './valeur-generated.reducer';

const valeurReducersFunctions = [...valeurReducersGeneratedFunctions];

const valeurReducer = createReducer(ValeurState.initialState, ...valeurReducersFunctions);

export function reducer(state: ValeurState.IState | undefined, action: Action) {
  return valeurReducer(state, action);
}
