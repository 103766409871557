import { User, UserSummary } from '@get/api-interfaces';

export function getFullName(user: User): string | null {
  return user
    ? user.prenom && user.nom
      ? `${user.prenom} ${user.nom}`
      : user.prenom || user.nom || user.pseudo || null
    : null;
}

export function getUserSummary(user: User): UserSummary {
  let userSummary: UserSummary = {
    pseudo: '',
    fullName: ''
  };

  if (user) {
    const pseudo = user.pseudo ? user.pseudo : user.prenom && user.nom ? `${user.prenom[0]}${user.nom[0]}` : '?';

    const fullName = getFullName(user);

    userSummary = { pseudo, fullName };
  }

  return userSummary;
}

export function getUserPseudo(user: User): string {
  const userName: UserSummary = getUserSummary(user);

  return userName.pseudo;
}
