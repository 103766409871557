import { Valeur } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const ValeurActions = createActionGroup({
  source: 'Valeur',
  events: {
    // 'Upsert One Valeur And Return Composant': props<{
    //   valeur: Partial<Valeur>;
    //   params?: { idCampagne?: number };
    // }>(),
    // 'Upsert Many Valeurs And Return Composants': props<{
    //   valeurs: Partial<Valeur>[];
    //   params?: { idCampagne?: number };
    // }>(),
  }
});
