import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganisationNewsletterApiService } from '@get/store/api-services';
import { GeneratedOrganisationNewsletterEffects } from './organisation-newsletter-generated.effects';

@Injectable()
export class OrganisationNewsletterEffects extends GeneratedOrganisationNewsletterEffects {
  constructor(actions$: Actions, organisationNewsletterApiService: OrganisationNewsletterApiService, store$: Store<AppState>) {
    super(actions$, organisationNewsletterApiService, store$);
  }
}
