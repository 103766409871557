import { GridError } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const GridErrorPatrimoineActions = createActionGroup({
  source: 'Grid Error Patrimoine',
  events: {
    'Add One Grid Error Patrimoine': props<{
      gridErrorPatrimoine: GridError;
    }>(),
    'Delete One Grid Error Patrimoine Success': props<{ idGridError: number }>()
  }
});
