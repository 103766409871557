import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComposantTemplateApiService } from '@get/store/api-services';
import { GeneratedComposantTemplateEffects } from './composant-template-generated.effects';

@Injectable()
export class ComposantTemplateEffects extends GeneratedComposantTemplateEffects {
  constructor(actions$: Actions, composantTemplateApiService: ComposantTemplateApiService, store$: Store<AppState>) {
    super(actions$, composantTemplateApiService, store$);
  }
}
