import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteProfilDroitApiService } from '@get/store/api-services';
import { GeneratedSocieteProfilDroitEffects } from './societe-profil-droit-generated.effects';
import {
  SocieteProfilDroitActions,
  SocieteProfilDroitGeneratedActions,
  SocieteProfilGeneratedActions
} from '@get/store/actions';
import { catchError, concatMap, map, of } from 'rxjs';
import { SocieteProfil, SocieteProfilDroitUpdate } from '@get/api-interfaces';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';

@Injectable()
export class SocieteProfilDroitEffects extends GeneratedSocieteProfilDroitEffects {
  constructor(actions$: Actions, societeProfilDroitApiService: SocieteProfilDroitApiService, store$: Store<AppState>) {
    super(actions$, societeProfilDroitApiService, store$);
  }

  upsertManySocieteProfilDroitWithSocieteProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteProfilDroitActions.upsertManySocieteProfilDroitWithSocieteProfil),
      concatMap(
        ({
          societeProfilDroitUpdate,
          ids
        }: {
          societeProfilDroitUpdate: SocieteProfilDroitUpdate;
          ids?: SocieteProfilDroitRelationsIds;
        }) => {
          return this.societeProfilDroitApiService
            .upsertManySocieteProfilDroitWithSocieteProfil(societeProfilDroitUpdate)
            .pipe(
              map((societeProfilReturned: SocieteProfil) => {
                return SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert({
                  societeProfils: [societeProfilReturned]
                });
              }),
              catchError(error => of(SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure({ error })))
            );
        }
      )
    );
  });
}
