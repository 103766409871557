import { Injectable } from '@angular/core';
import { AnalyzeSyntheticConfigsToHandle, SocieteConfigAnalyseSynthese } from '@get/api-interfaces';
import { SocieteConfigAnalyseSyntheseActions, SocieteConfigAnalyseSyntheseGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedSocieteConfigAnalyseSyntheseService } from './societe-config-analyse-synthese-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteConfigAnalyseSyntheseService extends GeneratedSocieteConfigAnalyseSyntheseService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public handleManySocieteConfigAnalyseSynthese(
    configs: AnalyzeSyntheticConfigsToHandle,
    getResult?: boolean
  ): void | Observable<SocieteConfigAnalyseSynthese[]> {
    this.store$.dispatch(SocieteConfigAnalyseSyntheseActions.handleManySocieteConfigAnalyseSynthese({ configs }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteConfigAnalyseSyntheseGeneratedActions.normalizeManySocieteConfigAnalyseSynthesesAfterUpsert,
        SocieteConfigAnalyseSyntheseGeneratedActions.societeConfigAnalyseSynthesesFailure,
        true
      );
    }
  }
}
