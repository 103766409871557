import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedValeurFichierApiService } from './valeur-fichier-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurFichierApiService extends GeneratedValeurFichierApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
