import { Injectable } from '@angular/core';
import { Composant, ComposantAttendu } from '@get/api-interfaces';
import {
  ComposantAttenduGeneratedActions,
  ComposantGeneratedActions,
  ValeurActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { ValeurApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { GeneratedValeurEffects } from './valeur-generated.effects';

@Injectable()
export class ValeurEffects extends GeneratedValeurEffects {
  constructor(actions$: Actions, valeurApiService: ValeurApiService, store$: Store<AppState>) {
    super(actions$, valeurApiService, store$);
  }

  // upsertOneValeurReturnComposant$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ValeurActions.upsertOneValeurAndReturnComposant),
  //     concatMap(({ valeur, params }) => {
  //       return this.valeurApiService.updateValeurReturnComposant(valeur, params).pipe(
  //         mergeMap(
  //           ({ composant, composantAttendus }: { composant: Composant; composantAttendus: ComposantAttendu[] }) => {
  //             return [
  //               ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [composant] }),
  //               ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
  //                 composantAttendus: composantAttendus
  //               })
  //             ];
  //           }
  //         ),
  //         catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
  //       );
  //     })
  //   );
  // });

  // upsertManyValeursReturnComposants$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ValeurActions.upsertManyValeursAndReturnComposants),
  //     concatMap(({ valeurs, params }) => {
  //       return this.valeurApiService.updateValeursReturnComposants(valeurs, params).pipe(
  //         mergeMap(
  //           ({ composants, composantAttendus }: { composants: Composant[]; composantAttendus: ComposantAttendu[] }) => {
  //             return [
  //               ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants }),
  //               ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
  //                 composantAttendus: composantAttendus
  //               })
  //             ];
  //           }
  //         ),
  //         catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
  //       );
  //     })
  //   );
  // });
}
