import { OrganisationNewsletterState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organisationNewsletterReducersGeneratedFunctions } from './organisation-newsletter-generated.reducer';

const organisationNewsletterReducersFunctions = [...organisationNewsletterReducersGeneratedFunctions];

const organisationNewsletterReducer = createReducer(
  OrganisationNewsletterState.initialState,
  ...organisationNewsletterReducersFunctions
);

export function reducer(state: OrganisationNewsletterState.IState | undefined, action: Action) {
  return organisationNewsletterReducer(state, action);
}
