export enum CampaignStepPathEnum {
  allCaracteristiques = 'all-caracteristics',
  // commonCaracteristiques = 'common-caracteristics',
  composant = 'component',
  // composantAttendu = 'component-expected',
  // composantNumber = 'component-number',
  highestPatrimoine = 'highest-patrimony',
  patrimoine = 'patrimony',
  synthesis = 'synthesis'
}

export enum CampaignMonitoringEnum {
  edit = 'edit',
  monitoring = 'monitoring'
}
