import { NewsletterState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { newsletterReducersGeneratedFunctions } from './newsletter-generated.reducer';

const newsletterReducersFunctions = [...newsletterReducersGeneratedFunctions];

const newsletterReducer = createReducer(NewsletterState.initialState, ...newsletterReducersFunctions);

export function reducer(state: NewsletterState.IState | undefined, action: Action) {
  return newsletterReducer(state, action);
}
