import { FichierState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { fichierReducersGeneratedFunctions } from './fichier-generated.reducer';

const fichierReducersFunctions = [...fichierReducersGeneratedFunctions];

const fichierReducer = createReducer(FichierState.initialState, ...fichierReducersFunctions);

export function reducer(state: FichierState.IState | undefined, action: Action) {
  return fichierReducer(state, action);
}
