import { UserSocieteProfilState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userSocieteProfilReducersGeneratedFunctions } from './user-societe-profil-generated.reducer';

const userSocieteProfilReducersFunctions = [...userSocieteProfilReducersGeneratedFunctions];

const userSocieteProfilReducer = createReducer(
  UserSocieteProfilState.initialState,
  ...userSocieteProfilReducersFunctions
);

export function reducer(state: UserSocieteProfilState.IState | undefined, action: Action) {
  return userSocieteProfilReducer(state, action);
}
