import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const SocietePatrimoineHierarchieActions = createActionGroup({
  source: 'Societe Patrimoine Hierarchie',
  events: {
    'Upsert One Societe Patrimoine Hierarchie With Tree': props<{
      societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>;
      ids?: SocietePatrimoineHierarchieRelationsIds;
    }>(),
    'Get Patrimoines To Consult List': props<{
      params: any;
    }>(),
    'Delete Many Hierarchie Ancetres With Alias Success': props<{
      idHierarchieAncetres: number[];
      idSocietePatrimoineHierarchies: number[];
    }>(),
    'Delete Many Hierarchie Descendants With Alias Success': props<{
      idHierarchieDescendants: number[];
      idSocietePatrimoineHierarchies: number[];
    }>(),
    'Add Many Hierarchie Ancetres With Alias Success': props<{
      idSocietePatrimoineHierarchie: number;
      idHierarchieAncetres: number[];
    }>(),
    'Add Many Hierarchie Descendants With Alias Success': props<{
      idSocietePatrimoineHierarchie: number;
      idHierarchieDescendants: number[];
    }>(),
    'Delete One Societe Patrimoine Hierarchie With Cascade': props<{ idSocietePatrimoineHierarchie: number }>()
  }
});
