import { ComposantState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { composantReducersGeneratedFunctions } from './composant-generated.reducer';

const composantReducersFunctions = [...composantReducersGeneratedFunctions];

const composantReducer = createReducer(ComposantState.initialState, ...composantReducersFunctions);

export function reducer(state: ComposantState.IState | undefined, action: Action) {
  return composantReducer(state, action);
}
