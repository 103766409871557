import { Injectable } from '@angular/core';import { RepositoryService } from '@get/services/repository';
import { GeneratedOrganisationNewsletterApiService } from './organisation-newsletter-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationNewsletterApiService extends GeneratedOrganisationNewsletterApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
