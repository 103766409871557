import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserNewsletterApiService } from '@get/store/api-services';
import { GeneratedUserNewsletterEffects } from './user-newsletter-generated.effects';

@Injectable()
export class UserNewsletterEffects extends GeneratedUserNewsletterEffects {
  constructor(actions$: Actions, userNewsletterApiService: UserNewsletterApiService, store$: Store<AppState>) {
    super(actions$, userNewsletterApiService, store$);
  }
}
