export * from './societe-espace-famille-generated.selectors';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  hydrateAll,
  selectSocieteEspaceFamillesEntities,
  societeEspaceFamilleRelations
} from './societe-espace-famille-generated.selectors';

const filterSocietePatrimoineHierarchies = (
  societePatrimoineHierarchies: Dictionary<SocieteEspaceFamilleEntityState>
) =>
  Object.keys(societePatrimoineHierarchies)
    .filter(idPatrimoineHierarchies => !societePatrimoineHierarchies[idPatrimoineHierarchies]?.idSocieteEspaceFamille)
    .reduce(
      (acc, idPatrimoineHierarchies) =>
        Object.assign(acc, {
          [idPatrimoineHierarchies]: societePatrimoineHierarchies[idPatrimoineHierarchies]
        }),
      {}
    );

const societePatrimoineHierarchiesInObject = (societeEspaceFamilles: Dictionary<SocieteEspaceFamilleEntityState>) => ({
  societeEspaceFamilles: filterSocietePatrimoineHierarchies(societeEspaceFamilles)
});

const selectSocietePatrimoineHierarchiesEntitiesDictionary = createSelector(
  selectSocieteEspaceFamillesEntities,
  societePatrimoineHierarchiesInObject
);

const selectAllSocietePatrimoineHierarchiesObject = createSelector(
  selectSocieteEspaceFamillesEntities,
  societeEspaceFamilles => {
    return hydrateAll({
      societeEspaceFamilles: filterSocietePatrimoineHierarchies(societeEspaceFamilles)
    });
  }
);

const selectAllSocietePatrimoineHierarchiesSelectors: Dictionary<Selector> = {};
export function selectAllSocietePatrimoineHierarchies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SocieteEspaceFamille>(
      schema,
      selectAllSocietePatrimoineHierarchiesSelectors,
      selectSocietePatrimoineHierarchiesEntitiesDictionary,
      getRelationSelectors,
      societeEspaceFamilleRelations,
      hydrateAll,
      'societeEspaceFamille'
    );
  } else {
    return selectAllSocietePatrimoineHierarchiesObject;
  }
}
