import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedDroitApiService } from './droit-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class DroitApiService extends GeneratedDroitApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
