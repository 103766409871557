import { Injectable } from '@angular/core';
import { EspaceSynchronizationResult, SocieteComposant, SocieteEspaceFamille } from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedSocieteEspaceFamilleApiService } from './societe-espace-famille-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteEspaceFamilleApiService extends GeneratedSocieteEspaceFamilleApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateSocieteEspaceFamilleWithSocieteComposant(
    societeEspaceFamille: Partial<SocieteEspaceFamille>
  ): Observable<{
    societeEspaceFamille: SocieteEspaceFamille;
    societeComposantsToReturn: SocieteComposant[];
  }> {
    return this.repo.update('societe-espace-famille', societeEspaceFamille);
  }

  public deleteOneSocieteEspaceFamilleWithSocieteEspace(
    idSocieteEspaceFamille: number
  ): Observable<{ affectedRows: number }> {
    return this.repo.delete('societe-espace-famille/' + +idSocieteEspaceFamille);
  }

  public synchronizeEspaces(idSociete?: number): Observable<EspaceSynchronizationResult> {
    return this.repo.create<EspaceSynchronizationResult>(
      `societe-espace-famille/synchronize${idSociete ? '/' + idSociete : ''}`,
      {}
    );
  }
}
