import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginData, LoginDataUser, TokenUser } from '@get/api-interfaces';
import { environment } from '@get/environment';
import { DbService, StorageService } from '@get/services/storage';
import { AppState, clearState } from '@get/store/configs/reducers';
import { UserService } from '@get/store/services';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private store: Store<AppState>,
    private userService: UserService,
    private dbService: DbService
  ) {}

  public isLogged = false;
  public loginData?: LoginData;

  public login(credentials: { email: string; password: string }): Observable<LoginDataUser> {
    return this.http.post<LoginDataUser>(environment.apiUrl + '/auth/login', credentials);
  }

  public reset(credentials: { password: string; token: string }): Observable<void> {
    return this.http.put<void>(environment.apiUrl + '/auth/reset', credentials);
  }

  public sendReset(credentials: { emails: string[]; type: string }): Observable<void> {
    return this.http.post<void>(environment.apiUrl + '/auth/send-reset-email', credentials);
  }

  public tokenUser(credentials: { token: string }): Observable<TokenUser> {
    return this.http.get<TokenUser>(environment.apiUrl + '/auth/token-user?token=' + credentials.token);
  }

  public renew(): Observable<LoginDataUser> {
    return this.http.get<LoginDataUser>(environment.apiUrl + '/auth/renew');
  }

  public getLoginStorage(): void {
    const loginData = this.storageService.isLogged();
    if (!loginData) {
      return;
    } else {
      this.loginData = loginData;
      this.isLogged = true;
    }
  }

  private async clearIndexedDb(): Promise<void> {
    const databaseCollection = await this.dbService.getDatabaseCollection();
    this.dbService.clearIndexedDbTable(databaseCollection, {
      tableKey: 'organisations',
      primaryKey: 'idOrganisation'
    });
    this.dbService.clearIndexedDbTable(databaseCollection, {
      tableKey: 'societe-composant-descriptions',
      primaryKey: 'idSocieteComposant'
    });
    this.dbService.clearIndexedDbTable(databaseCollection, {
      tableKey: 'patrimoines',
      primaryKey: 'idPatrimoine'
    });
    this.dbService.clearIndexedDbTable(databaseCollection, {
      tableKey: 'societe-patrimoine-hierarchies',
      primaryKey: 'idSocietePatrimoineHierarchie'
    });
  }

  public logout(): void {
    this.clearIndexedDb();
    this.storageService.clearAll();
    this.userService.currentUser = null;
    this.loginData = undefined;
    this.isLogged = false;
    this.store.dispatch(clearState({ states: [] }));
  }
}
