import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampagneUserApiService } from '@get/store/api-services';
import { GeneratedCampagneUserEffects } from './campagne-user-generated.effects';

@Injectable()
export class CampagneUserEffects extends GeneratedCampagneUserEffects {
  constructor(actions$: Actions, campagneUserApiService: CampagneUserApiService, store$: Store<AppState>) {
    super(actions$, campagneUserApiService, store$);
  }
}
