import { ValeurPatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurPatrimoineReducersGeneratedFunctions } from './valeur-patrimoine-generated.reducer';

const valeurPatrimoineReducersFunctions = [...valeurPatrimoineReducersGeneratedFunctions];

const valeurPatrimoineReducer = createReducer(ValeurPatrimoineState.initialState, ...valeurPatrimoineReducersFunctions);

export function reducer(state: ValeurPatrimoineState.IState | undefined, action: Action) {
  return valeurPatrimoineReducer(state, action);
}
