import { Injectable } from '@angular/core';
import { RepositoryService } from '@get/services/repository';
import { GeneratedOrganisationAzureLoginApiService } from './organisation-azure-login-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationAzureLoginApiService extends GeneratedOrganisationAzureLoginApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}

