import { ICellEditorComp } from '@ag-grid-community/core';
import dayjs from 'dayjs';

export class NativeDatePickerEditor implements ICellEditorComp {
  private eInput!: HTMLInputElement;
  private key_delete = 'Delete';

  public init(params: any): void {
    this.eInput = document.createElement('input');
    this.eInput.setAttribute('type', 'date');
    if (params?.min) {
      this.eInput.setAttribute('min', dayjs(params.min).format('YYYY-MM-DD'));
    }
    this.eInput.style.width = '140px';
    this.eInput.value =
      params.value && params.eventKey !== this.key_delete ? new Date(params.value).toISOString().substring(0, 10) : '';
  }

  public getGui(): HTMLInputElement {
    return this.eInput;
  }

  public afterGuiAttached(): void {
    this.eInput.select();
  }

  public getValue(): string | null {
    return this.eInput.value ? new Date(this.eInput.value).toISOString() : null;
  }

  public isPopup(): boolean {
    return true;
  }
}
