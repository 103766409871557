import { UserPatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { userPatrimoineReducersGeneratedFunctions } from './user-patrimoine-generated.reducer';

const userPatrimoineReducersFunctions = [...userPatrimoineReducersGeneratedFunctions];

const userPatrimoineReducer = createReducer(UserPatrimoineState.initialState, ...userPatrimoineReducersFunctions);

export function reducer(state: UserPatrimoineState.IState | undefined, action: Action) {
  return userPatrimoineReducer(state, action);
}
