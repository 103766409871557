import { Injectable } from '@angular/core';
import { AppState } from '@get/store/configs/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GeneratedSocieteTerritoireService } from './societe-territoire-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteTerritoireService extends GeneratedSocieteTerritoireService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
