import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedSocieteProfilApiService } from './societe-profil-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteProfilApiService extends GeneratedSocieteProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
