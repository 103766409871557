import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationEnum } from '@get/enums';
import { ConfirmationComponent } from '@get/shared/confirmation';
import { InformationComponent } from '@get/shared/information';
import { first, Observable, of, Subject, switchMap } from 'rxjs';

export interface IsDirty {
  dirtyMessage?: string;
  dirtyMessageIsInformation?: boolean;
  haveDenyFunc?: boolean;
  checkIfDirty(): boolean | Observable<boolean>;
  upsert?(): boolean;
  denied?(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IsNotDirtyGuard  {
  constructor(private dialog: MatDialog) {}

  public handleModal(component: IsDirty): Observable<boolean> {
    const navigateSubject$ = new Subject<boolean>();

    const dialogRef = this.dialog.open(
      component.dirtyMessageIsInformation ? InformationComponent : ConfirmationComponent,
      {
        data: {
          title: 'Confirmation',
          question: component.dirtyMessage || 'Voulez-vous sauvegarder les modifications apportées?'
        }
      }
    );

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: ConfirmationEnum) => {
        if (component.dirtyMessageIsInformation) {
          return navigateSubject$.next(false);
        }
        let navigate = true;
        if (result === ConfirmationEnum.valid && component.upsert) {
          navigate = component.upsert();
        }

        if (result === ConfirmationEnum.canceled) {
          navigate = false;
        }

        if (result === ConfirmationEnum.denied && component.haveDenyFunc && component.denied) {
          navigate = component.denied();
        }

        navigateSubject$.next(navigate);
      });

    return navigateSubject$.asObservable();
  }

  public canDeactivate(component: IsDirty): Observable<boolean> | boolean {
    const isDirty = component.checkIfDirty();

    if (!isDirty) {
      return true;
    }
    if (typeof isDirty === 'boolean') {
      return this.handleModal(component);
    } else {
      return isDirty.pipe(switchMap(dirty => (dirty ? this.handleModal(component) : of(true))));
    }
  }
}
