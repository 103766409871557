import { OrganisationAzureLoginState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { organisationAzureLoginReducersGeneratedFunctions } from './organisation-azure-login-generated.reducer';

const organisationAzureLoginReducersFunctions = [...organisationAzureLoginReducersGeneratedFunctions];

const organisationAzureLoginReducer = createReducer(
  OrganisationAzureLoginState.initialState,
  ...organisationAzureLoginReducersFunctions
);

export function reducer(state: OrganisationAzureLoginState.IState | undefined, action: Action) {
  return organisationAzureLoginReducer(state, action);
}
