import { CampagneState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { campagneReducersGeneratedFunctions } from './campagne-generated.reducer';

const campagneReducersFunctions = [...campagneReducersGeneratedFunctions];

const campagneReducer = createReducer(CampagneState.initialState, ...campagneReducersFunctions);

export function reducer(state: CampagneState.IState | undefined, action: Action) {
  return campagneReducer(state, action);
}
