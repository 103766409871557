import { Injectable } from '@angular/core';
import { SocieteEspaceFamille } from '@get/api-interfaces';
import { SocieteEspaceFamilleActions, SocieteEspaceFamilleGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { SocieteEspaceFamilleRelationsIds } from '@get/store/ids-interfaces';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedSocieteEspaceFamilleService } from './societe-espace-famille-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SocieteEspaceFamilleService extends GeneratedSocieteEspaceFamilleService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public upsertOneSocieteEspaceFamilleWithSocieteComposant(
    societeEspaceFamille: Partial<SocieteEspaceFamille>,
    ids: SocieteEspaceFamilleRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteEspaceFamille> {
    this.store$.dispatch(
      SocieteEspaceFamilleActions.upsertOneSocieteEspaceFamilleWithSocieteComposant({ societeEspaceFamille, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceFamilleGeneratedActions.normalizeManySocieteEspaceFamillesAfterUpsert,
        SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure,
        true
      );
    }
  }

  public deleteOneSocieteEspaceFamilleWithSocieteEspace(
    idSocieteEspaceFamille: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(
      SocieteEspaceFamilleActions.deleteOneSocieteEspaceFamilleWithSocieteEspace({ idSocieteEspaceFamille })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteEspaceFamilleGeneratedActions.deleteOneSocieteEspaceFamilleSuccess,
        SocieteEspaceFamilleGeneratedActions.societeEspaceFamillesFailure
      );
    }
  }
  // TODO: Check si on peut utiliser les ids aussi dans le cas d'un update pour plus de cohérence dans le store
}
