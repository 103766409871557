import { EspaceState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { espaceReducersGeneratedFunctions } from './espace-generated.reducer';

const espaceReducersFunctions = [...espaceReducersGeneratedFunctions];

const espaceReducer = createReducer(EspaceState.initialState, ...espaceReducersFunctions);

export function reducer(state: EspaceState.IState | undefined, action: Action) {
  return espaceReducer(state, action);
}
