import { PatrimoineHierarchieTemplateState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { patrimoineHierarchieTemplateReducersGeneratedFunctions } from './patrimoine-hierarchie-template-generated.reducer';

const patrimoineHierarchieTemplateReducersFunctions = [...patrimoineHierarchieTemplateReducersGeneratedFunctions];

const patrimoineHierarchieTemplateReducer = createReducer(
  PatrimoineHierarchieTemplateState.initialState,
  ...patrimoineHierarchieTemplateReducersFunctions
);

export function reducer(state: PatrimoineHierarchieTemplateState.IState | undefined, action: Action) {
  return patrimoineHierarchieTemplateReducer(state, action);
}
