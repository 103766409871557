import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteTerritoirePatrimoineApiService } from '@get/store/api-services';
import { GeneratedSocieteTerritoirePatrimoineEffects } from './societe-territoire-patrimoine-generated.effects';

@Injectable()
export class SocieteTerritoirePatrimoineEffects extends GeneratedSocieteTerritoirePatrimoineEffects {
  constructor(
    actions$: Actions,
    societeTerritoirePatrimoineApiService: SocieteTerritoirePatrimoineApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeTerritoirePatrimoineApiService, store$);
  }
}
