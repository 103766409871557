import { Injectable } from '@angular/core';
import { Composant, ComposantAttendu, Valeur } from '@get/api-interfaces';

import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedValeurApiService } from './valeur-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurApiService extends GeneratedValeurApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public deleteOrphanValeurs(): Observable<number[]> {
    return this.repo.delete('valeur/purge-orphan');
  }

  // public updateValeurReturnComposant(
  //   valeur: Partial<Valeur>,
  //   params?: { idCampagne?: number }
  // ): Observable<{ composant: Composant; composantAttendus: ComposantAttendu[] }> {
  //   return this.repo.update('valeur/return-composant', valeur, params);
  // }

  // public updateValeursReturnComposants(
  //   valeurs: Partial<Valeur>[],
  //   params?: { idCampagne?: number }
  // ): Observable<{ composants: Composant[]; composantAttendus: ComposantAttendu[] }> {
  //   return this.repo.update('valeur/many-return-composants', valeurs, params);
  // }
}
