import { Injectable } from '@angular/core';
import { CaracteristiqueChoixTemplateUpdate } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateActions, CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedCaracteristiqueChoixTemplateService } from './caracteristique-choix-template-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CaracteristiqueChoixTemplateService extends GeneratedCaracteristiqueChoixTemplateService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public handleManyCaracteristiqueChoixTemplate(
    params: CaracteristiqueChoixTemplateUpdate,
    getResult?: boolean
  ): void | Observable<any> {
    this.store$.dispatch(CaracteristiqueChoixTemplateActions.handleManyCaracteristiqueChoixTemplate(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CaracteristiqueChoixTemplateGeneratedActions.normalizeManyCaracteristiqueChoixTemplatesAfterUpsert,
        CaracteristiqueChoixTemplateGeneratedActions.caracteristiqueChoixTemplatesFailure,
        true
      );
    }
  }
}
