import { Injectable } from '@angular/core';
import { SocietePatrimoineHierarchie } from '@get/api-interfaces';
import { AppState } from '@get/store/configs/reducers';
import { SocietePatrimoineHierarchieActions, SocietePatrimoineHierarchieGeneratedActions } from '@get/store/actions';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchApiActions, SelectSchema } from '@get/utils';
import { mapTo, Observable, switchMap } from 'rxjs';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';
import { GeneratedSocietePatrimoineHierarchieService } from './societe-patrimoine-hierarchie-generated.service';
import { SocietePatrimoineHierarchieSelectors } from '@get/store/selectors';

@Injectable({
  providedIn: 'root'
})
export class SocietePatrimoineHierarchieService extends GeneratedSocietePatrimoineHierarchieService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public selectHighestSocietePatrimoineHierarchies(schema: SelectSchema): Observable<SocietePatrimoineHierarchie[]> {
    return this.store$
      .pipe(select(SocietePatrimoineHierarchieSelectors.selectHighestSocietePatrimoineHierarchies(schema)))
      .pipe(
        switchMap(
          ({ societePatrimoineHierarchies }: { societePatrimoineHierarchies: SocietePatrimoineHierarchie[] }) => {
            return this.getReady(schema).pipe(mapTo(societePatrimoineHierarchies));
          }
        )
      );
  }

  public selectSmallestSocietePatrimoineHierarchies(schema: SelectSchema): Observable<SocietePatrimoineHierarchie[]> {
    return this.store$
      .pipe(select(SocietePatrimoineHierarchieSelectors.selectSmallestSocietePatrimoineHierarchies(schema)))
      .pipe(
        switchMap(
          ({ societePatrimoineHierarchies }: { societePatrimoineHierarchies: SocietePatrimoineHierarchie[] }) => {
            return this.getReady(schema).pipe(mapTo(societePatrimoineHierarchies));
          }
        )
      );
  }

  public getPatrimoinesToConsultList(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocietePatrimoineHierarchie[]> {
    this.store$.dispatch(SocietePatrimoineHierarchieActions.getPatrimoinesToConsultList({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert,
        SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure
      );
    }
  }

  public override upsertOneSocietePatrimoineHierarchie(
    societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>,
    ids: SocietePatrimoineHierarchieRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocietePatrimoineHierarchie> {
    this.store$.dispatch(
      SocietePatrimoineHierarchieActions.upsertOneSocietePatrimoineHierarchieWithTree({
        societePatrimoineHierarchie,
        ids
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert,
        SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure,
        true
      );
    }
  }

  public deleteOneSocietePatrimoineHierarchieWithCascade(idSocietePatrimoineHierarchie: number): void {
    this.store$.dispatch(
      SocietePatrimoineHierarchieActions.deleteOneSocietePatrimoineHierarchieWithCascade({
        idSocietePatrimoineHierarchie
      })
    );
  }
}
