import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValeurPatrimoineApiService } from '@get/store/api-services';
import { GeneratedValeurPatrimoineEffects } from './valeur-patrimoine-generated.effects';

@Injectable()
export class ValeurPatrimoineEffects extends GeneratedValeurPatrimoineEffects {
  constructor(actions$: Actions, valeurPatrimoineApiService: ValeurPatrimoineApiService, store$: Store<AppState>) {
    super(actions$, valeurPatrimoineApiService, store$);
  }
}
