import { SocieteComposantState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeComposantReducersGeneratedFunctions } from './societe-composant-generated.reducer';

const societeComposantReducersFunctions = [...societeComposantReducersGeneratedFunctions];

const societeComposantReducer = createReducer(SocieteComposantState.initialState, ...societeComposantReducersFunctions);

export function reducer(state: SocieteComposantState.IState | undefined, action: Action) {
  return societeComposantReducer(state, action);
}
