import { SocieteTerritoirePatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeTerritoirePatrimoineReducersGeneratedFunctions } from './societe-territoire-patrimoine-generated.reducer';

const societeTerritoirePatrimoineReducersFunctions = [...societeTerritoirePatrimoineReducersGeneratedFunctions];

const societeTerritoirePatrimoineReducer = createReducer(
  SocieteTerritoirePatrimoineState.initialState,
  ...societeTerritoirePatrimoineReducersFunctions
);

export function reducer(state: SocieteTerritoirePatrimoineState.IState | undefined, action: Action) {
  return societeTerritoirePatrimoineReducer(state, action);
}
