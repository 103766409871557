import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DroitApiService } from '@get/store/api-services';
import { GeneratedDroitEffects } from './droit-generated.effects';

@Injectable()
export class DroitEffects extends GeneratedDroitEffects {
  constructor(actions$: Actions, droitApiService: DroitApiService, store$: Store<AppState>) {
    super(actions$, droitApiService, store$);
  }
}
