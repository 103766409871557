// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OrganisationParamsUtils {
  let _currentOrganisationId: number;

  export function setCurrentOrganisationId(id: number): void {
    _currentOrganisationId = id;
  }

  export function getCurrentOrganisationId(): number {
    return _currentOrganisationId;
  }
}
