import { SocieteComposantFamilleState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeComposantFamilleReducersGeneratedFunctions } from './societe-composant-famille-generated.reducer';

const societeComposantFamilleReducersFunctions = [...societeComposantFamilleReducersGeneratedFunctions];

const societeComposantFamilleReducer = createReducer(
  SocieteComposantFamilleState.initialState,
  ...societeComposantFamilleReducersFunctions
);

export function reducer(state: SocieteComposantFamilleState.IState | undefined, action: Action) {
  return societeComposantFamilleReducer(state, action);
}
