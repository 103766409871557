import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedValeurComposantApiService } from './valeur-composant-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ValeurComposantApiService extends GeneratedValeurComposantApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
