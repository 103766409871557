import { Injectable } from '@angular/core';
import { GridError, SocieteEspaceFamilleDataRow } from '@get/api-interfaces';
import { GridErrorSocieteEspaceFamilleActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorSocieteEspaceFamilleSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorSocieteEspaceFamilleService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorSocieteEspaceFamille: Partial<GridError>): number | undefined {
    if (!gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille) {
      gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille = --this.tempId;
    }
    gridErrorSocieteEspaceFamille.idGridError = gridErrorSocieteEspaceFamille.error.idSocieteEspaceFamille;
    this.store$.dispatch(
      GridErrorSocieteEspaceFamilleActions.addOneGridErrorSocieteEspaceFamille({
        gridErrorSocieteEspaceFamille: gridErrorSocieteEspaceFamille as GridError
      })
    );
    return gridErrorSocieteEspaceFamille.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(
      GridErrorSocieteEspaceFamilleActions.deleteOneGridErrorSocieteEspaceFamilleSuccess({ idGridError })
    );
  }

  public selectSocieteEspaceFamilleAndGridErrors(schema: SelectSchema = {}): Observable<SocieteEspaceFamilleDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSocieteEspaceFamilleSelectors.selectSocieteEspaceFamilleAndGridErrors(schema)))
      .pipe(map(societeEspaceFamilleDataRow => societeEspaceFamilleDataRow));
  }

  public selectPatrimoineHierachieAndGridErrors(schema: SelectSchema = {}): Observable<SocieteEspaceFamilleDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSocieteEspaceFamilleSelectors.selectPatrimoineHierachieAndGridErrors(schema)))
      .pipe(map(societeEspaceFamilleDataRow => societeEspaceFamilleDataRow));
  }
}
