import { CampagneUserState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { campagneUserReducersGeneratedFunctions } from './campagne-user-generated.reducer';

const campagneUserReducersFunctions = [...campagneUserReducersGeneratedFunctions];

const campagneUserReducer = createReducer(CampagneUserState.initialState, ...campagneUserReducersFunctions);

export function reducer(state: CampagneUserState.IState | undefined, action: Action) {
  return campagneUserReducer(state, action);
}
