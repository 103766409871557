import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModulePathKeyEnum } from '@enums';
import { DeviceService } from '@get/services/device';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CannotLoadOnMobileGuard  {
  constructor(private deviceService: DeviceService, private router: Router) {}

  public canLoad(): Observable<boolean> | boolean {
    return this.deviceService.isOnMobile$().pipe(
      map(isOnMobile => !isOnMobile),
      tap(isNotOnMobile => {
        if (!isNotOnMobile) {
          this.router.navigate(['app', ModulePathKeyEnum.consult]);
        }
      })
    );
  }
}
