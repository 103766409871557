import { ComposantAttenduReponseEnum } from '@enums';
import { ComposantAttendu } from '@get/api-interfaces';

export function calculateCompletion(composantAttendus: ComposantAttendu[]): number {
  const vals = composantAttendus.reduce(
    (acc, composantAttendu) => {
      acc.num += composantAttendu.nbReponsesRempli;
      acc.denom += composantAttendu.nbReponsesAttendu;
      return acc;
    },
    { num: 0, denom: 0 }
  );
  return vals.denom === 0 ? 0 : vals.num / vals.denom;
}

export function getClassForCompletionPercentage(completion?: number): string {
  return completion === undefined || isNaN(completion)
    ? 'progress-grey'
    : completion < 40
    ? 'progress-red'
    : completion >= 40 && completion < 60
    ? 'progress-orange'
    : completion >= 60 && completion < 80
    ? 'progress-yellow'
    : completion >= 80 && completion < 90
    ? 'progress-green-2'
    : 'progress-green-1';
}

export function getClassForCompletionPercentageAndComposantAttendu(
  composantAttendu: ComposantAttendu,
  completion?: number
): string {
  return composantAttendu.reponse === ComposantAttenduReponseEnum.non
    ? 'progress-red'
    : getClassForCompletionPercentage(completion);
}

export function getProgressText(completion?: number, precision?: number): string {
  const pow = Math.pow(10, precision ?? 0);
  return completion === undefined || isNaN(completion)
    ? ''
    : completion === 0
    ? 'À collecter'
    : precision && completion
    ? Math.round(parseFloat(completion.toFixed(precision)) * pow) / pow + '%'
    : completion + '%';
}
