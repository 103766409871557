import { FileTypeEnum } from '@enums';
import { Fichier, ValeurFichier } from '@get/api-interfaces';

export function isFichierImage(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.image;
}

export function isFichierDocument(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.document;
}

export function isFichierIcon(fichier: Fichier): boolean {
  return fichier?.fileType === FileTypeEnum.icon;
}

export function filterValeurFichiers(
  valeurFichiers: ValeurFichier[],
  typeChecker: (fichier: Fichier) => boolean
): ValeurFichier[] {
  return valeurFichiers?.filter(valeurFichier => typeChecker(valeurFichier?.fichier));
}
