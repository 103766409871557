import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteComposantFamilleApiService } from '@get/store/api-services';
import { GeneratedSocieteComposantFamilleEffects } from './societe-composant-famille-generated.effects';

@Injectable()
export class SocieteComposantFamilleEffects extends GeneratedSocieteComposantFamilleEffects {
  constructor(
    actions$: Actions,
    societeComposantFamilleApiService: SocieteComposantFamilleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeComposantFamilleApiService, store$);
  }
}
