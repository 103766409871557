import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampagneSocieteCaracteristiqueApiService } from '@get/store/api-services';
import { GeneratedCampagneSocieteCaracteristiqueEffects } from './campagne-societe-caracteristique-generated.effects';

@Injectable()
export class CampagneSocieteCaracteristiqueEffects extends GeneratedCampagneSocieteCaracteristiqueEffects {
  constructor(
    actions$: Actions,
    campagneSocieteCaracteristiqueApiService: CampagneSocieteCaracteristiqueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, campagneSocieteCaracteristiqueApiService, store$);
  }
}
