import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteEspaceApiService } from '@get/store/api-services';
import { GeneratedSocieteEspaceEffects } from './societe-espace-generated.effects';

@Injectable()
export class SocieteEspaceEffects extends GeneratedSocieteEspaceEffects {
  constructor(actions$: Actions, societeEspaceApiService: SocieteEspaceApiService, store$: Store<AppState>) {
    super(actions$, societeEspaceApiService, store$);
  }
}
