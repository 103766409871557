import { Injectable } from '@angular/core';
import {
  SocieteCaracteristiqueEntityState,
  SocieteComposant,
  SocieteComposantEntityState,
  SocieteComposantToHandle
} from '@get/api-interfaces';
import { SocieteComposantActions, SocieteComposantGeneratedActions } from '@get/store/actions';
import { SocieteComposantApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { AppState } from '@get/store/configs/reducers';
import { SocieteCaracteristiqueSelectors, SocieteComposantSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { getDefaultDeleteSocieteCaracteristiqueActions } from './societe-caracteristique-generated.effects';
import {
  GeneratedSocieteComposantEffects,
  getDefaultDeleteSocieteComposantActions
} from './societe-composant-generated.effects';

@Injectable()
export class SocieteComposantEffects extends GeneratedSocieteComposantEffects {
  constructor(actions$: Actions, societeComposantApiService: SocieteComposantApiService, store$: Store<AppState>) {
    super(actions$, societeComposantApiService, store$);
  }

  getManySocieteComposantDescriptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantActions.getManySocieteComposantDescriptions),
      switchMap(({ params }) =>
        this.societeComposantApiService.getSocieteComposantDescriptions(params).pipe(
          map((societeComposants: SocieteComposant[]) => {
            return SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({ societeComposants });
          }),
          catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
        )
      )
    );
  });

  handleManySocieteComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocieteComposantActions.handleManySocieteComposants),
      concatMap((params: SocieteComposantToHandle) => {
        return this.societeComposantApiService.handleManySocieteComposants(params).pipe(
          map((societeComposantReturned: SocieteComposant[]) => {
            return SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert({
              societeComposants: societeComposantReturned
            });
          }),
          catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
        );
      })
    );
  });

  deleteOneSocieteComposantWithCascade$ = createEffect(() => {
    const selectSocieteComposantState$ = this.store$.select(SocieteComposantSelectors.selectSocieteComposantState);
    const selectSocieteCaracteristiqueState$ = this.store$.select(
      SocieteCaracteristiqueSelectors.selectSocieteCaracteristiqueState
    );
    return this.actions$.pipe(
      ofType(SocieteComposantActions.deleteOneSocieteComposantWithCascade),
      withLatestFrom(selectSocieteComposantState$, selectSocieteCaracteristiqueState$),
      concatMap(([{ idSocieteComposant }, state, societeCaracteristiqueState]) =>
        this.societeComposantApiService.deleteSocieteComposant(idSocieteComposant).pipe(
          mergeMap(_success => {
            const societeCaracteristiquesToDelete = state.entities[idSocieteComposant]
              ?.societeCaracteristiques as number[];
            const actions =
              societeCaracteristiquesToDelete
                ?.map(idSocieteCaracteristique =>
                  getDefaultDeleteSocieteCaracteristiqueActions(
                    societeCaracteristiqueState.entities[idSocieteCaracteristique] as SocieteCaracteristiqueEntityState
                  )
                )
                .flat() || [];
            return [
              getMultiAction(
                getDefaultDeleteSocieteComposantActions(
                  state.entities[idSocieteComposant] as SocieteComposantEntityState
                ).concat(actions),
                SocieteComposantActions.deleteOneSocieteComposantWithCascade.type
              )
            ];
          }),
          catchError(error => of(SocieteComposantGeneratedActions.societeComposantsFailure({ error })))
        )
      )
    );
  });
}
