import { ValeurFichierState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { valeurFichierReducersGeneratedFunctions } from './valeur-fichier-generated.reducer';

const valeurFichierReducersFunctions = [...valeurFichierReducersGeneratedFunctions];

const valeurFichierReducer = createReducer(ValeurFichierState.initialState, ...valeurFichierReducersFunctions);

export function reducer(state: ValeurFichierState.IState | undefined, action: Action) {
  return valeurFichierReducer(state, action);
}
