import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserApiService } from '@get/store/api-services';
import { GeneratedUserEffects } from './user-generated.effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { UserActions, UserGeneratedActions } from '@get/store/actions';
import { User } from '@get/api-interfaces';

@Injectable()
export class UserEffects extends GeneratedUserEffects {
  constructor(actions$: Actions, userApiService: UserApiService, store$: Store<AppState>) {
    super(actions$, userApiService, store$);
  }

  getManyForCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getManyForCampaign),
      switchMap(({ params }) =>
        this.userApiService.getManyForCampaign(params).pipe(
          map((users: User[]) => {
            return UserGeneratedActions.normalizeManyUsersAfterUpsert({ users });
          }),
          catchError(error => of(UserGeneratedActions.usersFailure({ error })))
        )
      )
    );
  });
}
