import { OrganisationActions } from '@get/store/actions';
import { OrganisationState } from '@get/store/states';
import { Action, createReducer, on } from '@ngrx/store';
import { organisationReducersGeneratedFunctions, setLoadingsState } from './organisation-generated.reducer';

const organisationReducersFunctions = [...organisationReducersGeneratedFunctions];

const organisationReducer = createReducer(
  OrganisationState.initialState,
  ...organisationReducersFunctions,
  on(OrganisationActions.getUserOrganisation, (state: OrganisationState.IState) => setLoadingsState(state, true))
);

export function reducer(state: OrganisationState.IState | undefined, action: Action) {
  return organisationReducer(state, action);
}
