import { LibelleInternRightsEnum } from '@enums';
import { User } from '@get/api-interfaces';

export function getUserRight(user: Partial<User> | null): LibelleInternRightsEnum[] {
  if (!user) {
    return [];
  }
  const rights: LibelleInternRightsEnum[][] | undefined = user?.societeProfils?.map(
    profil => profil.droits?.map(droit => droit.libelleIntern) as LibelleInternRightsEnum[]
  );
  if (!rights) {
    return [];
  }
  return Array.prototype.concat.apply([], rights);
}

export function userHaveRight(
  right: LibelleInternRightsEnum | LibelleInternRightsEnum[],
  user: Partial<User> | null
): boolean {
  if (!right) {
    return false;
  } else if (typeof right === 'string') {
    return getUserRight(user).includes(right);
  } else if (Array.isArray(right)) {
    return !right.map(it => getUserRight(user).includes(it)).includes(false);
  } else {
    return false;
  }
}
