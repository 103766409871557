import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@get/services/auth';

@Injectable({
  providedIn: 'root'
})
export class IsUserNotLogged  {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(): boolean {
    if (this.authService.isLogged) {
      this.router.navigate(['/app']);
      return false;
    }
    return true;
  }
}
