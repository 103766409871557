import { createEntityAdapter } from '@ngrx/entity';
import { SocieteEntityState } from '@get/api-interfaces';
import { CustomEntityState } from '@get/store/configs/states';

export type IState = CustomEntityState<SocieteEntityState>;

export const adapter = createEntityAdapter<SocieteEntityState>({
  selectId: o => o.idSociete
});
export const initialState: IState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const societeFeatureKey = 'societe';
