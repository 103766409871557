import { Injectable } from '@angular/core';
import {
  SocieteCaracteristiqueEntityState,
  SocietePatrimoineHierarchie,
  SocietePatrimoineHierarchieAncetreEntityState,
  SocietePatrimoineHierarchieEntityState,
  SocietePatrimoineHierarchieUpdate
} from '@get/api-interfaces';
import {
  SocietePatrimoineHierarchieActions,
  SocietePatrimoineHierarchieAncetreGeneratedActions,
  SocietePatrimoineHierarchieGeneratedActions
} from '@get/store/actions';
import { SocietePatrimoineHierarchieApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { AppState } from '@get/store/configs/reducers';
import { SocietePatrimoineHierarchieRelationsIds } from '@get/store/ids-interfaces';
import {
  SocieteCaracteristiqueSelectors,
  SocietePatrimoineHierarchieAncetreSelectors,
  SocietePatrimoineHierarchieSelectors
} from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { getDefaultDeleteSocieteCaracteristiqueActions } from './societe-caracteristique-generated.effects';
import {
  GeneratedSocietePatrimoineHierarchieEffects,
  getDefaultAddSocietePatrimoineHierarchieActions,
  getDefaultDeleteSocietePatrimoineHierarchieActions
} from './societe-patrimoine-hierarchie-generated.effects';

@Injectable()
export class SocietePatrimoineHierarchieEffects extends GeneratedSocietePatrimoineHierarchieEffects {
  constructor(
    actions$: Actions,
    societePatrimoineHierarchieApiService: SocietePatrimoineHierarchieApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societePatrimoineHierarchieApiService, store$);
  }

  getPatrimoinesToConsultList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieActions.getPatrimoinesToConsultList),
      switchMap(({ params }) =>
        this.societePatrimoineHierarchieApiService.getPatrimoinesToConsultList(params).pipe(
          map((societePatrimoineHierarchies: SocietePatrimoineHierarchie[]) => {
            return SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({
              societePatrimoineHierarchies
            });
          }),
          catchError(error =>
            of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error }))
          )
        )
      )
    );
  });

  deleteOneSocietePatrimoineHierarchieWithCascade$ = createEffect(() => {
    const selectSocietePatrimoineHierarchieState$ = this.store$.select(
      SocietePatrimoineHierarchieSelectors.selectSocietePatrimoineHierarchieState
    );
    const selectSocieteCaracteristiqueState$ = this.store$.select(
      SocieteCaracteristiqueSelectors.selectSocieteCaracteristiqueState
    );
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieActions.deleteOneSocietePatrimoineHierarchieWithCascade),
      withLatestFrom(selectSocietePatrimoineHierarchieState$, selectSocieteCaracteristiqueState$),
      concatMap(([{ idSocietePatrimoineHierarchie }, state, societeCaracteristiqueState]) =>
        this.societePatrimoineHierarchieApiService
          .deleteSocietePatrimoineHierarchie(idSocietePatrimoineHierarchie)
          .pipe(
            mergeMap(_success => {
              const societeCaracteristiquesToDelete = state.entities[idSocietePatrimoineHierarchie]
                ?.societeCaracteristiques as number[];
              const actions =
                societeCaracteristiquesToDelete
                  ?.map(idSocieteCaracteristique =>
                    getDefaultDeleteSocieteCaracteristiqueActions(
                      societeCaracteristiqueState.entities[
                        idSocieteCaracteristique
                      ] as SocieteCaracteristiqueEntityState
                    )
                  )
                  .flat() || [];
              return [
                getMultiAction(
                  getDefaultDeleteSocietePatrimoineHierarchieActions(
                    state.entities[idSocietePatrimoineHierarchie] as SocietePatrimoineHierarchieEntityState
                  ).concat(actions),
                  SocietePatrimoineHierarchieActions.deleteOneSocietePatrimoineHierarchieWithCascade.type
                )
              ];
            }),
            catchError(error =>
              of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneSocietePatrimoineHierarchieWithTree$ = createEffect(() => {
    const selectSocietePatrimoineHierarchieAncetreState$ = this.store$.select(
      SocietePatrimoineHierarchieAncetreSelectors.selectSocietePatrimoineHierarchieAncetreState
    );
    return this.actions$.pipe(
      ofType(SocietePatrimoineHierarchieActions.upsertOneSocietePatrimoineHierarchieWithTree),
      withLatestFrom(selectSocietePatrimoineHierarchieAncetreState$),
      concatMap(
        ([{ societePatrimoineHierarchie, ids }, societePatrimoineHierarchieAncetreState]: [
          {
            societePatrimoineHierarchie: Partial<SocietePatrimoineHierarchie>;
            ids?: SocietePatrimoineHierarchieRelationsIds;
          },
          any
        ]) => {
          if (societePatrimoineHierarchie.idSocietePatrimoineHierarchie) {
            return this.societePatrimoineHierarchieApiService
              .updateSocietePatrimoineHierarchieWithTree(societePatrimoineHierarchie)
              .pipe(
                mergeMap((updateObj: SocietePatrimoineHierarchieUpdate) => {
                  const actions: Action[] = [];
                  if (updateObj.ancetresDeleted?.length) {
                    for (let i = 0; i < updateObj.ancetresDeleted.length; i++) {
                      const ancetreToDelete = updateObj.ancetresDeleted[i];
                      // TODO: Gérer les alias correctement dans le delete dans les schematics pour pouvoir utiliser ce code pour la partie delete
                      // (Vérif d'abord si c'est faisable dans les schematics)
                      // actions = actions.concat(
                      //   getDefaultDeleteSocietePatrimoineHierarchieAncetreActions(
                      //     societePatrimoineHierarchieAncetreState.entities[
                      //       ancetreToDelete
                      //     ] as SocietePatrimoineHierarchieAncetreEntityState
                      //   )
                      // );

                      const entity = societePatrimoineHierarchieAncetreState.entities[
                        ancetreToDelete
                      ] as SocietePatrimoineHierarchieAncetreEntityState;

                      actions.push(
                        SocietePatrimoineHierarchieAncetreGeneratedActions.deleteOneSocietePatrimoineHierarchieAncetreSuccess(
                          {
                            idSocietePatrimoineHierarchieAncetre: entity.idSocietePatrimoineHierarchieAncetre
                          }
                        )
                      );

                      if (entity.idHierarchieAncetre) {
                        actions.push(
                          SocietePatrimoineHierarchieActions.deleteManyHierarchieDescendantsWithAliasSuccess({
                            idHierarchieDescendants: [entity.idSocietePatrimoineHierarchieAncetre],
                            idSocietePatrimoineHierarchies: [entity.idHierarchieAncetre]
                          })
                        );
                      }

                      if (entity.idHierarchieDescendant) {
                        actions.push(
                          SocietePatrimoineHierarchieActions.deleteManyHierarchieAncetresWithAliasSuccess({
                            idHierarchieAncetres: [entity.idSocietePatrimoineHierarchieAncetre],
                            idSocietePatrimoineHierarchies: [entity.idHierarchieDescendant]
                          })
                        );
                      }
                    }
                  }

                  if (updateObj.ancetresCreated?.length) {
                    actions.push(
                      SocietePatrimoineHierarchieAncetreGeneratedActions.upsertManySocietePatrimoineHierarchieAncetresSuccess(
                        {
                          societePatrimoineHierarchieAncetres: updateObj.ancetresCreated
                        }
                      )
                    );
                    for (let i = 0; i < updateObj.ancetresCreated.length; i++) {
                      const ancetreToCreate = updateObj.ancetresCreated[i];

                      actions.push(
                        SocietePatrimoineHierarchieActions.addManyHierarchieAncetresWithAliasSuccess({
                          idSocietePatrimoineHierarchie: ancetreToCreate.idHierarchieDescendant,
                          idHierarchieAncetres: [ancetreToCreate.idSocietePatrimoineHierarchieAncetre]
                        })
                      );
                      actions.push(
                        SocietePatrimoineHierarchieActions.addManyHierarchieDescendantsWithAliasSuccess({
                          idSocietePatrimoineHierarchie: ancetreToCreate.idHierarchieAncetre,
                          idHierarchieDescendants: [ancetreToCreate.idSocietePatrimoineHierarchieAncetre]
                        })
                      );
                    }
                  }

                  const finalActionsArray: Action[] = [
                    SocietePatrimoineHierarchieGeneratedActions.normalizeManySocietePatrimoineHierarchiesAfterUpsert({
                      societePatrimoineHierarchies: [updateObj.patrimoineHierarchieUpdated]
                    })
                  ];
                  if (actions.length) {
                    if (actions.length > 1) {
                      finalActionsArray.push(
                        getMultiAction(
                          actions,
                          SocietePatrimoineHierarchieActions.upsertOneSocietePatrimoineHierarchieWithTree.type
                        )
                      );
                    } else {
                      finalActionsArray.push(...actions);
                    }
                  }
                  return finalActionsArray;
                }),
                catchError(error =>
                  of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error }))
                )
              );
          } else {
            return this.societePatrimoineHierarchieApiService
              .addSocietePatrimoineHierarchie(societePatrimoineHierarchie)
              .pipe(
                mergeMap((societePatrimoineHierarchieReturned: SocietePatrimoineHierarchie) =>
                  getDefaultAddSocietePatrimoineHierarchieActions(societePatrimoineHierarchieReturned, ids)
                ),
                catchError(error =>
                  of(SocietePatrimoineHierarchieGeneratedActions.societePatrimoineHierarchiesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });
}
