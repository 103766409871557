import { Injectable } from '@angular/core';
import { GridError, PatrimoineDataRow } from '@get/api-interfaces';
import { GridErrorPatrimoineActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorPatrimoineSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorPatrimoineService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorPatrimoine: Partial<GridError>): number | undefined {
    if (!gridErrorPatrimoine.error.idPatrimoine) {
      gridErrorPatrimoine.error.idPatrimoine = --this.tempId;
    }
    gridErrorPatrimoine.idGridError = gridErrorPatrimoine.error.idPatrimoine;
    this.store$.dispatch(
      GridErrorPatrimoineActions.addOneGridErrorPatrimoine({
        gridErrorPatrimoine: gridErrorPatrimoine as GridError
      })
    );
    return gridErrorPatrimoine.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(GridErrorPatrimoineActions.deleteOneGridErrorPatrimoineSuccess({ idGridError }));
  }

  public selectPatrimoineAndGridErrors(schema: SelectSchema = {}): Observable<PatrimoineDataRow[]> {
    return this.store$
      .pipe(select(GridErrorPatrimoineSelectors.selectPatrimoineAndGridErrors(schema)))
      .pipe(map(patrimoineDataRow => patrimoineDataRow));
  }
}
