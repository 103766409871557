import {
  Composant,
  ComposantAttendu,
  ComposantAttenduCardInterface,
  ComposantGroupe,
  Espace,
  Fichier,
  Patrimoine,
  Societe,
  SocieteCaracteristique,
  SocieteCaracteristiqueChoix,
  SocieteComposant,
  SocieteComposantRattachement,
  SocieteEspace,
  SocietePatrimoineHierarchie,
  Valeur,
  ValeurFichier
} from '@get/api-interfaces';

export const valeurTrackByFn = (_index: number, value: Valeur) => value.idValeur;

export const espaceTrackByFn = (_index: number, value: Espace) => value.idEspace;

export const composantAttenduTrackByFn = (_index: number, value: ComposantAttendu) => value.idComposantAttendu;

export const composantAttenduCardInterfaceTrackByFn = (_index: number, value: ComposantAttenduCardInterface) =>
  value.id;

export const composantGroupeTrackByFn = (_index: number, value: ComposantGroupe) => value.idComposantGroupe;

export const composantTrackByFn = (_index: number, value: Composant) => value.idComposant;

export const societeTrackByFn = (_index: number, value: Societe) => value.idSociete;

export const societePatrimoineHierarchieTrackByFn = (_index: number, value: SocietePatrimoineHierarchie) =>
  value.idSocietePatrimoineHierarchie;

export const societeComposantTrackByFn = (_index: number, value: SocieteComposant) => value.idSocieteComposant;

export const societeEspaceTrackByFn = (_index: number, value: SocieteEspace) => value.idSocieteEspace;

export const societeComposantRattachementTrackByFn = (_index: number, value: SocieteComposantRattachement) =>
  value.idSocieteComposantRattachement;

export const societeCaracteristiqueTrackByFn = (_index: number, value: SocieteCaracteristique) =>
  value.idSocieteCaracteristique;

export const societeCaracteristiqueChoixTrackByFn = (_index: number, value: SocieteCaracteristiqueChoix) =>
  value?.idSocieteCaracteristiqueChoix;

export const patrimoineTrackByFn = (_index: number, value: Patrimoine) => value.idPatrimoine;

export const fichierTrackByFn = (_index: number, value: Fichier) => value.idFichier;

export const valeurFichierTrackByFn = (_index: number, value: ValeurFichier) => value.idValeurFichier;

export const genericIdTrackByFn = (_index: number, value: { id: number }) => value.id;
