import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@get/store/configs/reducers';
import { GeneratedSocieteProfilDroitService } from './societe-profil-droit-generated.service';
import { Actions } from '@ngrx/effects';
import { SocieteProfil, SocieteProfilDroitUpdate } from '@get/api-interfaces';
import { SocieteProfilDroitRelationsIds } from '@get/store/ids-interfaces';
import {
  SocieteProfilDroitActions,
  SocieteProfilDroitGeneratedActions,
  SocieteProfilGeneratedActions
} from '@get/store/actions';
import { catchApiActions } from '@get/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocieteProfilDroitService extends GeneratedSocieteProfilDroitService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public upsertManySocieteProfilDroitWithSocieteProfil(
    societeProfilDroitUpdate: SocieteProfilDroitUpdate,
    ids: SocieteProfilDroitRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SocieteProfil> {
    this.store$.dispatch(
      SocieteProfilDroitActions.upsertManySocieteProfilDroitWithSocieteProfil({ societeProfilDroitUpdate, ids })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteProfilGeneratedActions.normalizeManySocieteProfilsAfterUpsert,
        SocieteProfilDroitGeneratedActions.societeProfilDroitsFailure,
        true
      );
    }
  }
}
