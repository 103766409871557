import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EspaceApiService } from '@get/store/api-services';
import { GeneratedEspaceEffects } from './espace-generated.effects';

@Injectable()
export class EspaceEffects extends GeneratedEspaceEffects {
  constructor(actions$: Actions, espaceApiService: EspaceApiService, store$: Store<AppState>) {
    super(actions$, espaceApiService, store$);
  }
}
