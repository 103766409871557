import { GridErrorPatrimoineActions } from '@get/store/actions';
import { GridErrorPatrimoineState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorPatrimoineState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorPatrimoineState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorPatrimoineState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(
    GridErrorPatrimoineActions.addOneGridErrorPatrimoine,
    (state: GridErrorPatrimoineState.IState, { gridErrorPatrimoine }) =>
      GridErrorPatrimoineState.adapter.upsertMany([gridErrorPatrimoine], setLoadingsState(state, false))
  ),
  on(
    GridErrorPatrimoineActions.deleteOneGridErrorPatrimoineSuccess,
    (state: GridErrorPatrimoineState.IState, { idGridError }) =>
      GridErrorPatrimoineState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorPatrimoineState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorPatrimoineState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
