import { Injectable } from '@angular/core';
import { AssertEmptyComposantAttenduInterface, ComposantAttendu } from '@get/api-interfaces';
import { ComposantAttenduActions, ComposantAttenduGeneratedActions } from '@get/store/actions';
import { ComposantAttenduApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedComposantAttenduEffects } from './composant-attendu-generated.effects';

@Injectable()
export class ComposantAttenduEffects extends GeneratedComposantAttenduEffects {
  constructor(actions$: Actions, composantAttenduApiService: ComposantAttenduApiService, store$: Store<AppState>) {
    super(actions$, composantAttenduApiService, store$);
  }

  // assertEmptyComposantAttendu$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ComposantAttenduActions.assertEmptyComposantAttendu),
  //     concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
  //       return this.composantAttenduApiService.assertEmptyComposantAttendu(params).pipe(
  //         map((composantAttenduReturned: ComposantAttendu[]) => {
  //           return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
  //             composantAttendus: composantAttenduReturned
  //           });
  //         }),
  //         catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
  //       );
  //     })
  //   );
  // });

  // assertEmptyTree$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ComposantAttenduActions.assertEmptyTree),
  //     concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
  //       return this.composantAttenduApiService.assertEmptyTree(params).pipe(
  //         map((composantAttenduReturned: ComposantAttendu[]) => {
  //           return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
  //             composantAttendus: composantAttenduReturned
  //           });
  //         }),
  //         catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
  //       );
  //     })
  //   );
  // });
}
