import { GridError } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const GridErrorSocieteComposantActions = createActionGroup({
  source: 'Grid Error Societe Composant',
  events: {
    'Add One Grid Error Societe Composant': props<{
      gridErrorSocieteComposant: GridError;
    }>(),
    'Delete One Grid Error Societe Composant Success': props<{ idGridError: number }>()
  }
});
