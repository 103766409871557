import { Injectable } from '@angular/core';
import {
  DeleteManyUserPatrimoineParams,
  UserPatrimoine,
  UserPatrimoineForPatrimoineParams,
  UserPatrimoineForUserParams
} from '@get/api-interfaces';
import { RepositoryService } from '@get/services/repository';
import { Observable } from 'rxjs';
import { GeneratedUserPatrimoineApiService } from './user-patrimoine-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserPatrimoineApiService extends GeneratedUserPatrimoineApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getManyUserPatrimoinesForUser(params: UserPatrimoineForUserParams): Observable<UserPatrimoine[]> {
    return this.repo.getData('user-patrimoine/for-user', params);
  }

  public getManyUserPatrimoinesForPatrimoine(params: UserPatrimoineForPatrimoineParams): Observable<UserPatrimoine[]> {
    return this.repo.getData('user-patrimoine/for-patrimoine', params);
  }

  public handleManyUserPatrimoines(usersPatrimoines: Partial<UserPatrimoine>[]): Observable<UserPatrimoine[]> {
    return this.repo.update('user-patrimoine/handle-many', usersPatrimoines);
  }

  public handleManyOwnUserPatrimoines(usersPatrimoines: Partial<UserPatrimoine>[]): Observable<UserPatrimoine[]> {
    return this.repo.update('user-patrimoine/handle-many-own', usersPatrimoines);
  }

  public createManyUserPatrimoines(usersPatrimoines: Partial<UserPatrimoine>[]): Observable<UserPatrimoine[]> {
    return this.repo.create('user-patrimoine/create-many', usersPatrimoines);
  }

  public deleteManyUserPatrimoine(params: DeleteManyUserPatrimoineParams): Observable<{ affectedRows: number }> {
    return this.repo.delete('user-patrimoine/user/' + +params.idUser + '/patrimoine/' + +params.idPatrimoine);
  }

  public deleteManyUserPatrimoinesByIds(idsUserPatrimoine: number[]): Observable<{ affectedRows: number }> {
    return this.repo.delete('user-patrimoine', { idsUserPatrimoine });
  }
}
