import { CampagneSocieteCaracteristiqueState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { campagneSocieteCaracteristiqueReducersGeneratedFunctions } from './campagne-societe-caracteristique-generated.reducer';

const campagneSocieteCaracteristiqueReducersFunctions = [...campagneSocieteCaracteristiqueReducersGeneratedFunctions];

const campagneSocieteCaracteristiqueReducer = createReducer(
  CampagneSocieteCaracteristiqueState.initialState,
  ...campagneSocieteCaracteristiqueReducersFunctions
);

export function reducer(state: CampagneSocieteCaracteristiqueState.IState | undefined, action: Action) {
  return campagneSocieteCaracteristiqueReducer(state, action);
}
