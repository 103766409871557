import { Injectable } from '@angular/core';
import {
  DeleteManyUserPatrimoineParams,
  UserPatrimoine,
  UserPatrimoineForPatrimoineParams,
  UserPatrimoineForUserParams
} from '@get/api-interfaces';
import { UserPatrimoineActions, UserPatrimoineGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedUserPatrimoineService } from './user-patrimoine-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserPatrimoineService extends GeneratedUserPatrimoineService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public getManyUserPatrimoinesForUser(
    params: UserPatrimoineForUserParams,
    getResult?: boolean
  ): void | Observable<UserPatrimoine[]> {
    this.store$.dispatch(UserPatrimoineActions.getManyUserPatrimoinesForUser({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure
      );
    }
  }

  public getManyUserPatrimoinesForPatrimoine(
    params: UserPatrimoineForPatrimoineParams,
    getResult?: boolean
  ): void | Observable<UserPatrimoine[]> {
    this.store$.dispatch(UserPatrimoineActions.getManyUserPatrimoinesForPatrimoine({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure
      );
    }
  }

  public handleManyUserPatrimoines(userPatrimoines: UserPatrimoine[], getResult?: boolean): void | Observable<any> {
    this.store$.dispatch(UserPatrimoineActions.handleManyUserPatrimoines({ userPatrimoines }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure,
        true
      );
    }
  }

  public handleManyOwnUserPatrimoines(userPatrimoines: UserPatrimoine[], getResult?: boolean): void | Observable<any> {
    this.store$.dispatch(UserPatrimoineActions.handleManyOwnUserPatrimoines({ userPatrimoines }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        UserPatrimoineGeneratedActions.normalizeManyUserPatrimoinesAfterUpsert,
        UserPatrimoineGeneratedActions.userPatrimoinesFailure,
        true
      );
    }
  }

  public createManyUserPatrimoines(
    userPatrimoines: UserPatrimoine[],
    origin: 'user' | 'patrimoine'
  ): void | Observable<UserPatrimoine[]> {
    this.store$.dispatch(UserPatrimoineActions.createManyUserPatrimoines({ userPatrimoines, origin }));
  }

  public deleteManyUserPatrimoine(params: DeleteManyUserPatrimoineParams): void | Observable<number[]> {
    this.store$.dispatch(UserPatrimoineActions.deleteManyUserPatrimoines({ params }));
  }

  public deleteManyUserPatrimoinesByIds(idsUserPatrimoine: number[]): void | Observable<number[]> {
    this.store$.dispatch(UserPatrimoineActions.deleteManyUserPatrimoinesByIds({ idsUserPatrimoine }));
  }
}
