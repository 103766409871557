import { Organisation } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const OrganisationActions = createActionGroup({
  source: 'Organisation',
  events: {
    'Get User Organisation': props<{ idUser: number; params?: any }>(),
    'Upsert Organisation On App Init': props<{ organisation: Organisation }>()
  }
});
