import { Injectable } from '@angular/core';
import { SocieteComposant, SocieteComposantToHandle } from '@get/api-interfaces';
import { SocieteComposantActions, SocieteComposantGeneratedActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GeneratedSocieteComposantService } from './societe-composant-generated.service';
import { catchApiActions } from '@get/utils';

@Injectable({
  providedIn: 'root'
})
export class SocieteComposantService extends GeneratedSocieteComposantService {
  constructor(store$: Store<AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public getManySocieteComposantDescriptions(
    params: any = {},
    getResult?: boolean
  ): void | Observable<SocieteComposant[]> {
    this.store$.dispatch(SocieteComposantActions.getManySocieteComposantDescriptions({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SocieteComposantGeneratedActions.normalizeManySocieteComposantsAfterUpsert,
        SocieteComposantGeneratedActions.societeComposantsFailure
      );
    }
  }

  public handleManySocieteComposants(params: SocieteComposantToHandle): void | Observable<SocieteComposant[]> {
    this.store$.dispatch(SocieteComposantActions.handleManySocieteComposants(params));
  }

  public deleteOneSocieteComposantWithCascade(idSocieteComposant: number): void {
    this.store$.dispatch(SocieteComposantActions.deleteOneSocieteComposantWithCascade({ idSocieteComposant }));
  }
}
