import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UserEntityState } from '@get/api-interfaces';
import { UserRelationsIds } from '@get/store/ids-interfaces';

export const UserGeneratedActions = createActionGroup({
  source: 'User Generated',
  events: {
    'Get One User': props<{ idUser: number; params?: any }>(),
    'Get Many Users': props<{ params: any }>(),
    'Add Many Actives Users': props<{ idUsers: number[] }>(),
    'Delete One Active User': props<{ idUser: number }>(),
    'Clear Actives Users': emptyProps(),
    'Upsert One User': props<{ user: Partial<User>; ids?: UserRelationsIds; }>(),
    'Upsert Many Users': props<{ users: Partial<User>[]; ids?: UserRelationsIds; }>(),
    'Upsert Many Users Success': props<{ users: UserEntityState[] }>(),
    'Delete One User': props<{ idUser: number }>(),
    'Delete One User Success': props<{ idUser: number }>(),
    'Normalize Many Users After Upsert': props<{ users: UserEntityState[] }>(),
    'Users Failure': props<{ error: HttpErrorResponse }>(),
    'Clear Users': emptyProps(),
    'Add Many Societe Territoire User Success': props<{ idUser: number; idSocieteTerritoireUsers: number[] }>(),
    'Delete Many Societe Territoire User Success': props<{ idSocieteTerritoireUsers: number[]; idUsers: number[] }>(),
    'Add Many Campagne User Success': props<{ idUser: number; idCampagneUsers: number[] }>(),
    'Delete Many Campagne User Success': props<{ idCampagneUsers: number[]; idUsers: number[] }>(),
    'Add Many Composant Attendu Success': props<{ idUser: number; idComposantAttendus: number[] }>(),
    'Delete Many Composant Attendu Success': props<{ idComposantAttendus: number[]; idUsers: number[] }>(),
    'Add Many Campagne Success': props<{ idUser: number; idCampagnes: number[] }>(),
    'Delete Many Campagne Success': props<{ idCampagnes: number[]; idUsers: number[] }>(),
    'Add Many User Societe Profil Success': props<{ idUser: number; idUserSocieteProfils: number[] }>(),
    'Delete Many User Societe Profil Success': props<{ idUserSocieteProfils: number[]; idUsers: number[] }>(),
    'Add Many Societe Profil Success': props<{ idUser: number; idSocieteProfils: number[] }>(),
    'Delete Many Societe Profil Success': props<{ idSocieteProfils: number[]; idUsers: number[] }>(),
    'Add Many User Patrimoine Success': props<{ idUser: number; idUserPatrimoines: number[] }>(),
    'Delete Many User Patrimoine Success': props<{ idUserPatrimoines: number[]; idUsers: number[] }>(),
    'Add Many Patrimoine Success': props<{ idUser: number; idPatrimoines: number[] }>(),
    'Delete Many Patrimoine Success': props<{ idPatrimoines: number[]; idUsers: number[] }>(),
    'Add Many Composant Success': props<{ idUser: number; idComposants: number[] }>(),
    'Delete Many Composant Success': props<{ idComposants: number[]; idUsers: number[] }>(),
    'Add Many Valeur Success': props<{ idUser: number; idValeurs: number[] }>(),
    'Delete Many Valeur Success': props<{ idValeurs: number[]; idUsers: number[] }>(),
    'Add Many User Newsletter Success': props<{ idUser: number; idUserNewsletters: number[] }>(),
    'Delete Many User Newsletter Success': props<{ idUserNewsletters: number[]; idUsers: number[] }>(),
    'Add Many Newsletter Success': props<{ idUser: number; idNewsletters: number[] }>(),
    'Delete Many Newsletter Success': props<{ idNewsletters: number[]; idUsers: number[] }>(),
    'Add Many Societe Config Analyse Synthese Success': props<{ idUser: number; idSocieteConfigAnalyseSyntheses: number[] }>(),
    'Delete Many Societe Config Analyse Synthese Success': props<{ idSocieteConfigAnalyseSyntheses: number[]; idUsers: number[] }>(),
    'Add Organisation Success': props<{ idUser: number; idOrganisation: number }>(),
    'Delete Many Organisation Success': props<{ idOrganisations: number[]; idUsers: number[] }>()
  }
});
