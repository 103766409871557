import { CaracteristiqueTypeEnum, GraphTypeEnum } from '@enums';
import {
  AnalyzeGraphicCaracteristiqueResult,
  AnalyzeGraphicDataColor,
  ColorValue,
  GraphElement,
  GraphWithSeries,
  SocieteCaracteristique
} from '@get/api-interfaces';
import { defaultResponse } from './default-global.utils';

export function formatCaracteristiqueResultToGraphData(
  societeCaracteristique: SocieteCaracteristique,
  caracteristiqueResult: AnalyzeGraphicCaracteristiqueResult
): AnalyzeGraphicDataColor {
  if (!societeCaracteristique || !caracteristiqueResult) {
    return { data: [], colors: [] };
  }
  const colorValues: ColorValue[] = [];
  const elements: GraphElement[] = [];
  if (societeCaracteristique.type === CaracteristiqueTypeEnum.choix) {
    for (let i = 0; i < societeCaracteristique.societeCaracteristiqueChoices?.length; i++) {
      const choix = societeCaracteristique.societeCaracteristiqueChoices[i];
      elements.push({
        name: choix.libelle,
        value: caracteristiqueResult[choix.idSocieteCaracteristiqueChoix]?.nb ?? 0
      });
      if (choix.couleur) {
        colorValues.push({
          name: choix.libelle,
          value: choix.couleur
        });
      }
    }
    if (caracteristiqueResult['null']) {
      elements.push({
        name: defaultResponse,
        value: caracteristiqueResult['null']?.nb ?? 0
      });
      colorValues.push({
        name: defaultResponse,
        value: '#C1C1C1'
      });
    }
  }
  if (societeCaracteristique.typeGraphique === GraphTypeEnum.barreEmpilee) {
    return {
      data: [{ name: societeCaracteristique.libelle, series: elements }] as GraphWithSeries[],
      colors: colorValues
    };
  } else if (
    societeCaracteristique.typeGraphique === GraphTypeEnum.camembert ||
    societeCaracteristique.typeGraphique === GraphTypeEnum.histogramme
  ) {
    return { data: elements, colors: colorValues };
  }
  return { data: [], colors: colorValues };
}
