import { AssertEmptyComposantAttenduInterface } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const ComposantAttenduActions = createActionGroup({
  source: 'Composant Attendu',
  events: {
    // 'Assert Empty Composant Attendu ': props<{ params: AssertEmptyComposantAttenduInterface }>(),
    // 'Assert Empty Tree ': props<{ params: AssertEmptyComposantAttenduInterface }>()
  }
});
