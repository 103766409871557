import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ModulePathKeyEnum } from '@enums';

@Injectable()
export class PatrimoineAccessInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && error.error.subCode === 'FORBIDDEN') {
          this.router.navigate(['app', ModulePathKeyEnum.consult]);
        }
        return throwError(() => error);
      })
    );
  }
}
