import { DynamicType, SocietePatrimoineHierarchie, SocietePatrimoineHierarchieAncetre } from '@get/api-interfaces';

export function getDirectHierarchieAncestor(
  elements: SocietePatrimoineHierarchieAncetre[]
): SocietePatrimoineHierarchieAncetre | null {
  return elements?.find(el => el.niveau === 1) || null;
}

export function isChildSocietePatrimoineHierarchie(
  id: number,
  idParent: number,
  obj: DynamicType<SocietePatrimoineHierarchie>
): boolean {
  const elem = obj[id];
  if (!elem) {
    return false;
  }
  return elem.hierarchieAncetres?.some(
    (ancetre: SocietePatrimoineHierarchieAncetre) => ancetre.idHierarchieAncetre === idParent
  );
}

export function isEqualOrChildSocietePatrimoineHierarchie(
  id: number,
  idParent: number,
  obj: DynamicType<SocietePatrimoineHierarchie>
): boolean {
  return id === idParent || isChildSocietePatrimoineHierarchie(id, idParent, obj);
}
