import { GridErrorActions } from '@get/store/actions';
import { GridErrorState } from '@get/store/states';
import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

const gridErrorReducersFunctions: ReducerTypes<GridErrorState.IState, readonly ActionCreator[]>[] = [];

export function setLoadingsState(
  state: GridErrorState.IState,
  isLoading: boolean,
  isLoaded = true
): GridErrorState.IState {
  return { ...state, isLoaded, isLoading };
}

gridErrorReducersFunctions.push(
  on(GridErrorActions.addOneGridError, (state: GridErrorState.IState, { gridError }) =>
    GridErrorState.adapter.upsertMany([gridError], setLoadingsState(state, false))
  ),
  on(GridErrorActions.deleteOneGridErrorSuccess, (state: GridErrorState.IState, { idGridError }) =>
    GridErrorState.adapter.removeOne(idGridError, setLoadingsState(state, false))
  )
);

const gridErrorReducer = createReducer(GridErrorState.initialState, ...gridErrorReducersFunctions);

export function reducer(state: GridErrorState.IState | undefined, action: Action) {
  return gridErrorReducer(state, action);
}
