import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteTerritoireApiService } from '@get/store/api-services';
import { GeneratedSocieteTerritoireEffects } from './societe-territoire-generated.effects';

@Injectable()
export class SocieteTerritoireEffects extends GeneratedSocieteTerritoireEffects {
  constructor(actions$: Actions, societeTerritoireApiService: SocieteTerritoireApiService, store$: Store<AppState>) {
    super(actions$, societeTerritoireApiService, store$);
  }
}
