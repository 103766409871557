import { GridError } from '@get/api-interfaces';
import { createActionGroup, props } from '@ngrx/store';

export const GridErrorSocieteTerritoireActions = createActionGroup({
  source: 'Grid Error Societe Territoire',
  events: {
    'Add One Grid Error Societe Territoire': props<{
      gridErrorSocieteTerritoire: GridError;
    }>(),
    'Delete One Grid Error Societe Territoire Success': props<{ idGridError: number }>()
  }
});
