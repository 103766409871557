import { Actions } from '@ngrx/effects';
import { AppState } from '@get/store/configs/reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocieteTerritoireUserApiService } from '@get/store/api-services';
import { GeneratedSocieteTerritoireUserEffects } from './societe-territoire-user-generated.effects';

@Injectable()
export class SocieteTerritoireUserEffects extends GeneratedSocieteTerritoireUserEffects {
  constructor(
    actions$: Actions,
    societeTerritoireUserApiService: SocieteTerritoireUserApiService,
    store$: Store<AppState>
  ) {
    super(actions$, societeTerritoireUserApiService, store$);
  }
}
