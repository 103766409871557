import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { createSelector } from '@ngrx/store';
import {
  hydrateAll,
  societePatrimoineHierarchieRelations,
  selectSocietePatrimoineHierarchiesEntities
} from './societe-patrimoine-hierarchie-generated.selectors';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { Dictionary } from '@ngrx/entity';

export * from './societe-patrimoine-hierarchie-generated.selectors';

const societePatrimoineHierarchiesInObject = (
  societePatrimoineHierarchies: Dictionary<SocietePatrimoineHierarchieEntityState>
) => ({ societePatrimoineHierarchies });

const selecSocietePatrimoineHierarchiesEntitiesDictionary = createSelector(
  selectSocietePatrimoineHierarchiesEntities,
  societePatrimoineHierarchiesInObject
);

const selectHighestSocietePatrimoineHierarchieSelectors: Dictionary<Selector> = {};
function selectSocietePatrimoineHierarchiesWithSchema(schema: SelectSchema = {}): Selector {
  return findOrCreateSelector<SocietePatrimoineHierarchie>(
    schema,
    selectHighestSocietePatrimoineHierarchieSelectors,
    selecSocietePatrimoineHierarchiesEntitiesDictionary,
    getRelationSelectors,
    societePatrimoineHierarchieRelations,
    hydrateAll,
    'societePatrimoineHierarchie'
  );
}

export function selectHighestSocietePatrimoineHierarchies(schema: SelectSchema = {}): Selector {
  return createSelector(
    selectSocietePatrimoineHierarchiesWithSchema(schema),
    ({ societePatrimoineHierarchies }: { societePatrimoineHierarchies: SocietePatrimoineHierarchie[] }) => ({
      societePatrimoineHierarchies: societePatrimoineHierarchies?.filter(
        societePatrimoineHierarchie => !societePatrimoineHierarchie.hierarchieAncetres?.length
      )
    })
  );
}

export function selectSmallestSocietePatrimoineHierarchies(schema: SelectSchema = {}): Selector {
  return createSelector(
    selectSocietePatrimoineHierarchiesWithSchema(schema),
    ({ societePatrimoineHierarchies }: { societePatrimoineHierarchies: SocietePatrimoineHierarchie[] }) => ({
      societePatrimoineHierarchies: societePatrimoineHierarchies?.filter(
        societePatrimoineHierarchie => !societePatrimoineHierarchie.hierarchieDescendants?.length
      )
    })
  );
}
