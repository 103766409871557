import { Injectable } from '@angular/core';
import { GridError, SocieteTerritoireDataRow } from '@get/api-interfaces';
import { GridErrorSocieteTerritoireActions } from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { GridErrorSocieteTerritoireSelectors } from '@get/store/selectors';
import { SelectSchema } from '@get/utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GridErrorSocieteTerritoireService {
  tempId = 0;
  constructor(private store$: Store<AppState>) {}

  public addOneGridError(gridErrorSocieteTerritoire: Partial<GridError>): number | undefined {
    if (!gridErrorSocieteTerritoire.error.idSocieteTerritoire) {
      gridErrorSocieteTerritoire.error.idSocieteTerritoire = --this.tempId;
    }
    gridErrorSocieteTerritoire.idGridError = gridErrorSocieteTerritoire.error.idSocieteTerritoire;
    this.store$.dispatch(
      GridErrorSocieteTerritoireActions.addOneGridErrorSocieteTerritoire({
        gridErrorSocieteTerritoire: gridErrorSocieteTerritoire as GridError
      })
    );
    return gridErrorSocieteTerritoire.idGridError;
  }

  public removeOneGridError(idGridError: number): void | Observable<number> {
    this.store$.dispatch(GridErrorSocieteTerritoireActions.deleteOneGridErrorSocieteTerritoireSuccess({ idGridError }));
  }

  public selectSocieteTerritoireAndGridErrors(schema: SelectSchema = {}): Observable<SocieteTerritoireDataRow[]> {
    return this.store$
      .pipe(select(GridErrorSocieteTerritoireSelectors.selectSocieteTerritoireAndGridErrors(schema)))
      .pipe(map(societeTerritoireDataRow => societeTerritoireDataRow));
  }
}
