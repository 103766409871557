import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private mobileBreakpoints = [Breakpoints.XSmall, Breakpoints.Small];

  constructor(private breakpointObserver: BreakpointObserver) {}

  public isOnMobile(): boolean {
    return this.breakpointObserver.isMatched(this.mobileBreakpoints);
  }

  public isOnMobile$(): Observable<boolean> {
    return this.breakpointObserver.observe(this.mobileBreakpoints).pipe(
      map((breakpointState: BreakpointState) => breakpointState.matches),
      distinctUntilChanged()
    );
  }
}
