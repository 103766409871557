import { SocieteTerritoireState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { societeTerritoireReducersGeneratedFunctions } from './societe-territoire-generated.reducer';

const societeTerritoireReducersFunctions = [...societeTerritoireReducersGeneratedFunctions];

const societeTerritoireReducer = createReducer(
  SocieteTerritoireState.initialState,
  ...societeTerritoireReducersFunctions
);

export function reducer(state: SocieteTerritoireState.IState | undefined, action: Action) {
  return societeTerritoireReducer(state, action);
}
