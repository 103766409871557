import { Injectable } from '@angular/core';

import { RepositoryService } from '@get/services/repository';
import { GeneratedCampagneUserApiService } from './campagne-user-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CampagneUserApiService extends GeneratedCampagneUserApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
