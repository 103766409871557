import { CampagnePatrimoineState } from '@get/store/states';
import { Action, createReducer } from '@ngrx/store';
import { campagnePatrimoineReducersGeneratedFunctions } from './campagne-patrimoine-generated.reducer';

const campagnePatrimoineReducersFunctions = [...campagnePatrimoineReducersGeneratedFunctions];

const campagnePatrimoineReducer = createReducer(
  CampagnePatrimoineState.initialState,
  ...campagnePatrimoineReducersFunctions
);

export function reducer(state: CampagnePatrimoineState.IState | undefined, action: Action) {
  return campagnePatrimoineReducer(state, action);
}
